export const lang = {
	"公司地址": "中國北京市朝陽區保利國際廣場T1棟9樓。",
	"公司介绍": "公司介紹",
	"品类介绍": "品類介紹",
	"服务能力": "服務能力",
	"需求反馈": "需求迴響",
	"合作伙伴": "合作夥伴",
	"联系我们": "聯繫我們",
	"全球优质供应链服务商": "全球優質供應鏈服務商",
	"为全球品牌和企业提供定制化产品解决方案": "為全球品牌和企業提供定制化產品解決方案",
	"华汾公司介绍": "華汾公司介紹",

	"company-profile-title1":"華汾（北京）國際商貿有限公司",
	"company-profile1":"是一家集實業產品研發、生產、設計、品牌孵化、全球品牌行銷、進出口貿易銷售為一體的綜合性國際貿易企業。 我們通過利用自研Web 3.0去中心化技術提供頂級互聯網行銷和數位管理服務。 我們擅長在全球貿易網絡中協調智慧數位戰畧，為品牌和企業提供卓越的價值。",

	"company-profile-title2":"戰畧優勢：",
	"company-profile2":"我們依託全國航運優勢資源，遍佈89座都市和90座機場的强大廣告網絡，有效覆蓋了90%以上的都市地標。 這種廣泛的影響力使我們能够與中國14億龐大的人口建立聯系，顯著提高了品牌知名度和影響力。",

	"company-profile-title3":"創新產品：",
	"company-profile3":"在華汾，我們為推動技術進步和創新而感到自豪。 我們向全球消費者推薦具有國際文化意義的高品質生活方式產品，在世界舞臺上展示中國的貿易發展和創新能力。",

	"company-profile-title4":"我們的產品系列：",
	"company-profile4":"華汾經營三個不同的產品系列",


	"company-profile-title5":"1.專有品牌",
	"company-profile5":" - 華汾開發和擁有的品牌，體現了我們對創新和品質的執著追求",

	"company-profile-title6":"2.國際授權品牌",
	"company-profile6":" - 我們擁有全球知名品牌的經銷權，確保質量和正品内容。",

	"company-profile-title7":"3.代理品牌",
	"company-profile7":" - 我們代理並進行經營的品牌，通過我們的專業知識來擴大其影響力。",

	"company-profile-title8":"綜合產業鏈：",
	"company-profile8":"我公司擁有集產品研發、生產、銷售、物流、行銷為一體的完整產業鏈。 這種全方位的運營模式確保了業務的順暢開展以及卓越的服務品質，這與我們為全球客戶及合作夥伴提供優質產品和解決方案的目標相契合。",


	"我们能提供的品类": "提供品類",
	"个护清洁": "個護清潔",
	"清洁工具": "清潔工具",
	"家庭清洁": "家庭清潔",
	"衣物清洁": "衣物清潔",
	"洗发护发": "洗髮護髮",
	"身体护理": "身體護理",
	"纸品湿巾": "紙品濕巾",
	"口腔护理": "口腔護理",
	"驱蚊驱虫": "驅蚊驅蟲",
	"食品酒饮": "食品酒飲",
	"乳饮冰品": "乳飲冰品",
	"中外名酒": "全球名酒",
	"方便速食": "方便速食",
	"粮油调味": "糧油調味",
	"咖啡": "咖啡",
	"休闲食品": "休閒食品",
	"美妆护肤": "美妝護膚",
	"面部护肤": "面部護膚",
	"香水": "香水",
	"彩妆": "彩妝",
	"家电": "家電",
	"家电配件": "家電配件",
	"厨房小电": "廚房小電",
	"清洁电器": "清潔電器",
	"个护健康": "個護健康",
	"空调": "空調",
	"冰箱": "冰柜",
	"电视": "電視",
	"厨卫大电": "廚衛大電",
	"视听影音": "視聽影音",
	"家居厨具": "家居廚具",
	"火机烟具": "打火機烟具",
	"餐具": "餐具",
	"烹饪厨具": "烹飪廚具",
	"水具酒具": "水具酒具",
	"生活日用": "生活日用",
	"居家布艺": "居家布藝",
	"床上用品": "床上用品",
	"汽摩生活": "汽摩生活",
	"电动车": "電動車",
	"摩托车": "機車",
	"农用机械": "農用機械",
	"搬运设备": "搬運設備",
	"装饰用品": "裝飾用品",
	"派对用品": "派對用品",
	"母婴": "母嬰",
	"奶粉": "奶粉",
	"尿布湿巾": "尿布濕巾",
	"洗护用品": "洗護用品",
	"营养辅食": "營養輔食",
	"喂养工具": "餵養工具",
	"欢迎访问 Go2Pie商城，查找商品": "歡迎訪問Go2Pie商城，查找商品",
	"我们的服务能力": "我們的服務能力",
	"贸易": "貿易",
	"自主工厂生产及国内外品牌直供": "自主生產與全球品牌",
	"自研国际自由贸易平台Goπ": "自研國際自由貿易平臺Goπ",
	"品牌授权及合作线下门店": "品牌授權及合作線下門店",
	"国内外各大品牌经销/代理商": "國內外各大品牌經銷/代理商",
	"国际线上交易支付管理系统": "國際線上交易支付管理系統",
	"营销": "行銷",
	"整合营销推广解决方案": "綜合行銷推廣解決方案",
	"媒体营销推广": "媒體行銷推廣",
	"社交平台运营": "社交平臺運營",
	"电商平台运营": "電商平臺運營",
	"内容创意及制作": "內容創意及製作",
	"物流配送": "物流配送",
	"顶级物流体系及航运支持": "頂級物流體系及航運支持",
	"全国物流和冷链配送": "全國物流和冷鏈配送",
	"国际供应及国际运输": "國際供應及國際運輸",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "提供銷售通路搭建、企業集采及一件代發等全方位服務",
	"需求反馈收集": "需求迴響收集",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "我們可以滿足您的所有需求，請留下您的需求，我們會及時與您聯系",
	"提交需求反馈表单": "提交需求回饋表單",
	"集团合作伙伴": "集團合作夥伴",
	"集团": "集團",
	"合作伙伴及品牌": "合作夥伴及品牌",
	"合作媒体": "合作媒體",
	"华汾版权所有": "華汾版權所有",

	"收起": "收起",
	"展开": "展開",


	"食品":"食品",


	"咖啡":"咖啡",
	"胶囊咖啡": "膠囊咖啡",
	"黑咖啡": "黑咖啡",
	"白咖啡": "白咖啡",
	"咖啡豆/粉": "咖啡豆/粉",
	"滴咖啡": "滴濾咖啡",
	"咖啡液": "液態咖啡",
	"咖啡伴侣": "咖啡伴侶",
	"速溶咖啡": "即溶咖啡",
	"即饮咖啡": "即飲咖啡",
	"速溶黑咖啡": "即溶黑咖啡",
	"冻干咖啡": "凍幹咖啡",
	"三倍的": "三倍的",

	"可饮用谷物":"可飲用穀物",
	"大米": "大米",
	"煎堆": "芝麻球",
	"奶茶": "奶茶",
	"豆浆": "豆漿",
	"莲藕淀粉": "藕粉",
	"全麦谷物": "全麥穀物",
	"谷物粉": "穀物粉",
	"水果麦片": "水果麥片",
	"代餐摇床": "代餐奶昔",
	"成人奶粉": "成人奶粉",
	"霍尼": "蜂蜜",

	"牛奶饮料和冰淇淋":"牛奶飲料和霜淇淋",
	"闪闪发光的水": "蘇打水",
	"碳酸饮料": "碳酸飲料",
	"能量饮料": "能量飲料",
	"乳酸菌饮料": "乳酸菌飲料",
	"果蔬汁": "果蔬汁",
	"茶饮料": "茶飲料",
	"酸奶": "酸嬭",
	"纯牛奶": "純牛奶",
	"饮用水": "飲用水",
	"冰淇淋": "霜淇淋",

	"中外著名葡萄酒":"全球名酒",
	"洋酒": "洋酒",
	"白酒类": "白酒類",
	"啤酒": "啤酒",
	"葡萄酒": "葡萄酒",
	"黄酒/保健酒": "黃酒/保健酒",

	"谷物和油调味品":"糧油調味品",
	"中国调味品": "中國調味品",
	"西餐": "西餐",
	"配菜": "配菜",
	"东南亚调味品": "東南亞調味品",
	"花生菜籽油": "花生菜籽油",
	"葵花籽油": "葵花籽油",
	"米油": "米油",
	"橄榄油": "橄欖油",
	"茶油": "茶油",
	"谷物": "穀物",
	"粉丝": "粉絲",
	"面粉": "麵粉",

	"南北干货":"南北乾貨",
	"银耳": "銀耳",
	"扇贝": "扇貝",
	"干蔬菜": "幹蔬菜",
	"真菌": "真菌",
	"龙眼": "龍眼",
	"莲子": "蓮子",
	"干蘑菇": "幹蘑菇",
	"干海产品": "幹海產品",
	"有机干货": "有機乾貨",

	"方便快餐":"方便速食",
	"午餐肉": "午餐肉",
	"自热食品": "自熱食品",
	"米粉": "米粉",
	"速溶汤": "即溶湯",
	"火腿": "火腿",
	"蜗牛米粉": "螺螄粉",
	"方便面": "速食麵",
	"粽子": "粽子",
	"青团": "青團",

	"零食":"零食",
	"低热量零食": "低熱量零食",
	"饼干和糕点": "餅乾和糕點",
	"愚蠢的": "幹肉條",
	"坚果和烤坚果": "堅果和烤堅果",
	"蜜饯水果": "蜜餞水果",
	"糖果": "糖果",
	"零食礼品包": "零食禮品包",
	"麻辣豆腐干": "麻辣豆腐乾",
	"红烧鸡爪": "紅燒雞爪",
	"薯片膨化": "膨化薯片",
	"果冻布丁": "果凍布丁",
	"巧克力": "巧克力",

	"茶":"茶",
	"菊花茶": "菊花茶",
	"绿茶": "綠茶",
	"红茶": "紅茶",
	"普洱茶": "普洱茶",
	"乌龙茶": "烏龍茶",
	"茉莉花茶": "茉莉花茶",
	"健康茶": "健康茶",
	"白茶": "白茶",
	"西湖龙井": "西湖龍井",
	"铁观音": "鐵觀音",
	"红茶": "紅茶",


	"生鲜":"生鮮",

	
	"快速烘焙":"快速烘焙",
	"方便面": "速食麵",
	"粤式早茶": "粵式早茶",
	"烘焙半成品": "烘焙半成品",
	"面条": "麵條",
	"饺子蒸饺和炸饺子": "蒸餃和煎餃",
	"馄饨": "餛飩",
	"奶酪黄油": "起司黃油",
	"蛋糕和甜点": "蛋糕和甜點",
	"汤圆元宵": "湯圓元宵",
	"披萨": "披薩",
	"蛋挞": "蛋撻",
	"低温粽子": "低溫粽子",

	"预先准备的菜肴":"預製菜",
	"油炸食品": "油炸食品",
	"便餐": "便餐",
	"熟食": "熟食",
	"火锅配料": "火鍋配料",
	"培根香肠": "培根香腸",
	"半成品菜肴": "半成品菜",
	"海鲜配菜": "海鮮配菜",
	"当地特产": "當地特產",
	"一个人": "單人餐",

	"肉类和家禽产品":"肉類和家禽產品",
	"冷肉": "冷肉",
	"牛排": "牛排",
	"牛肉": "牛肉",
	"鸡": "雞",
	"鸡蛋": "雞蛋",
	"猪肉": "猪肉",
	"羊肉": "羊肉",
	"鸭子": "鴨子",

	"蔬菜":"蔬菜",
	"大豆制品": "大豆製品",
	"根状茎": "根莖",
	"红薯": "紅薯",
	"有机时令蔬菜": "有機時令蔬菜",
	"玉米": "玉米",
	"茄瓜": "茄瓜",
	"绿叶蔬菜": "綠葉蔬菜",
	"洋葱、姜、大蒜和胡椒": "洋葱、薑、大蒜和胡椒",
	"冷冻蔬菜": "冷凍蔬菜",
	"新鲜蘑菇": "新鮮蘑菇",

	"海鲜": "海鮮",
	"新鲜": "新鮮",
	"鲑鱼": "鮭魚",
	"鱼": "魚",
	"小龙虾": "小龍蝦",
	"小虾": "小蝦",
	"贝类": "貝類",
	"大闸蟹": "大閘蟹",
	"蟹": "蟹",
	"海参": "海參",

	"新鲜水果":"新鮮水果",
	"进口水果": "進口水果",
	"桃、李、杏": "桃、李、杏",
	"苹果": "蘋果",
	"榴莲": "榴槤",
	"芒果": "芒果",
	"梨": "梨",
	"樱桃": "櫻桃",
	"猕猴桃": "奇異果",
	"荔枝": "荔枝",
	"蓝莓": "藍莓",
	"橙色": "柳丁",
	"橙色/橘黄色": "柳丁/柑橘",
	"椰青": "椰青",
	"水果礼盒/礼券": "水果禮盒/禮券",


	"个护清洁":"個護清潔",


	"纸品湿巾":"紙品濕巾",
	"抽纸": "抽紙",
	"手帕纸": "手帕紙",
	"卷纸": "卷紙",
	"湿巾": "濕巾",
	"厨房纸巾": "廚房紙巾",

	"洗发护发":"洗髮護髮",
	"洗发水": "洗髮乳",
	"护发素": "護髮乳",
	"发膜": "發膜",
	"生发液": "生髮液",
	"护发精油": "護髮精油",
	"洗护套装": "洗護套裝",

	"身体护理":"身體護理",
	"精油": "精油",
	"沐浴露": "沐浴露",
	"男士沐浴": "男士沐浴",
	"儿童沐浴": "兒童沐浴",
	"香皂": "香皂",
	"洗手液": "洗手液",
	"美体/工具": "美體/工具",
	"护手霜": "護手霜",
	"浴盐": "浴鹽",
	"身体护理套": "身體護理套",
	"花露水": "花露水",

	"口腔护理":"口腔护理",
	"牙膏": "牙膏",
	"儿童口腔": "兒童口腔護理",
	"牙刷": "牙刷",
	"漱口水": "漱口水",
	"牙线/牙线棒": "牙線/牙線棒",
	"口喷": "口噴",
	"牙贴": "牙貼",

	"衣物清洁":"衣物清潔",
	"洗衣液": "洗衣液",
	"洗衣粉": "洗衣粉",
	"凝珠": "凝珠",
	"洗衣皂": "洗衣皂",
	"柔顺剂": "柔順劑",
	"除菌液": "除菌液",

	"女性护理":"女性護理",
	"卫生巾": "衛生巾",
	"护垫": "護墊",
	"棉条": "棉條",
	"裤型卫生巾": "褲型衛生巾",
	"私处护理": "私處護理",

	"驱蚊驱虫":"驅蚊驅蟲",
	"灭蚁": "滅蟻",
	"杀蟑": "殺蟑",
	"驱鼠": "驅鼠",
	"除满": "除蟎 ",
	"驱蝇": "驅蠅",

	"清洁工具":"清潔工具",
	"拖把": "拖把",
	"垃圾桶": "垃圾桶",
	"垃圾袋": "垃圾袋",
	"清洁刷具": "清潔刷具",
	"抹布/百洁布": "抹布/百潔布",
	"手套/鞋套": "手套/鞋套",
	"一次性清洁用品": "一次性清潔用品",
	"扫把": "掃把",
	"脸盆/水桶": "臉盆/水桶",

	"发型设计":"髮型設計",
	"发胶": "發膠",
	"发蜡/泥": "發蠟/泥",
	"定型啫喱": "定型啫喱",
	"染发": "染髮",
	"假发": "假髮",
	"弹力素": "彈力素",
	"美发工具": "美髮工具",
	"蓬蓬粉": "蓬蓬粉",

	"家庭清洁":"家庭清潔",
	"油污净": "油汙淨",
	"洗洁精": "洗潔精",
	"洁厕剂": "潔廁劑",
	"消毒液": "消毒液",
	"地板清洁剂": "地板清潔劑",
	"除湿干燥剂": "除濕乾燥劑",

	"成人护理":"成人護理",
	"成人纸尿裤": "成人紙尿褲",
	"成人拉拉裤": "成人拉拉褲",
	"成人护理垫": "成人護理墊",
	"成人纸尿垫": "成人紙尿墊",


	"美妆护肤":"美妝護膚",


	"面部护肤":"面部護膚",
	"防晒": "防曬",
	"眼霜眼部精华": "眼霜眼部精華",
	"护肤": "護膚",
	"洁面": "潔面",
	"化妆水": "化妝水",
	"卸妆": "卸妝",
	"润唇膏": "潤唇膏",
	"眼膜": "眼膜",
	"T区护理": "T區護理",
	"唇膜唇部精华": "唇膜唇部精華",

	"彩妆":"彩妝",
	"隔离霜/妆前乳": "隔離霜/妝前乳",
	"粉底液/膏": "粉底液/膏",
	"唇彩唇蜜/唇釉": "唇彩唇蜜/唇釉",
	"气垫": "氣墊",
	"BB霜": "BB霜",
	"蜜粉/散粉": "蜜粉/散粉",
	"眉笔/眉粉": "眉筆/眉粉",
	"粉饼": "粉餅",
	"美甲": "美甲",
	"眼影": "眼影",
	"眼线笔/眼线液": "眼線筆/眼線液",
	"遮瑕": "遮瑕",
	"腮红/胭脂": "腮紅/胭脂",
	"睫毛膏/睫毛液": "睫毛膏/睫毛液",
	"CC霜": "CC霜",
	"修容/高光/阴影": "修容/高光/陰影",
	"唇笔/唇线笔": "唇筆/唇線筆",
	"女士香水": "女士香水",
	"男士香水": "男士香水",

	"美妆工具":"美妆工具",
	"美甲工具": "美甲工具",
	"化妆棉": "化妝棉",
	"化妆刷": "化妝刷",
	"粉扑/洗脸扑": "粉撲/洗臉撲",
	"假睫毛": "假睫毛",
	"修眉刀": "修眉刀",
	"双眼皮贴": "雙眼皮貼",
	"睫毛夹": "睫毛夾",
	"美妆工具": "美妝工具",
	"吸油纸": "吸油紙",


	"家居厨具":"家居廚具",


	"生活日用":"生活日用",
	"香薰/蜡烛": "香薰/蠟燭",
	"衣架": "衣架",
	"指甲刀": "指甲刀",
	"纸巾盒": "紙巾盒",
	"雨伞雨具": "雨傘雨具",
	"日杂小件": "日雜小件",
	"梳子": "梳子",
	"浴室置物架": "浴室置物架",
	"移动马桶": "移動馬桶",
	"保暖防护": "保暖防護",
	"洗漱杯": "洗漱杯",
	"搓澡巾": "搓澡巾",
	"马桶刷": "馬桶刷",
	"净化除味": "淨化除味",
	"化妆镜": "化妝鏡",

	"水具酒具":"水具酒具",
	"保温杯": "保溫杯",
	"玻璃杯": "玻璃杯",
	"塑料杯": "塑膠杯",
	"陶瓷/马克杯": "陶瓷/馬克杯",
	"保温壶": "保溫壺",
	"运动水壶": "運動水壺",
	"焖烧杯": "焖燒杯",
	"酒杯/酒具": "酒杯/酒具",

	"烹饪锅具":"烹飪鍋具",
	"炒锅": "炒鍋",
	"平底锅/煎锅": "平底鍋/煎鍋",
	"汤锅": "滾滾鍋",
	"蒸锅": "蒸鍋",
	"奶锅": "奶鍋",
	"高压锅": "高壓鍋",
	"火锅": "火鍋",

	"餐具":"餐具",
	"碗": "碗",
	"盘/碟": "盤/碟",
	"筷子": "筷子",
	"刀/叉/勺": "刀/叉/勺",
	"果盘/果篮": "果盤/果籃",
	"一次性用品": "一次性用品",

	"厨房配件":"廚房配件",
	"上水器": "上水器",
	"真空机": "真空機",
	"厨房储物器皿": "廚房儲物器皿",
	"壁挂置物架": "壁挂置物架",
	"落地置物架": "落地置物架",
	"台面置物架": "臺面置物架",
	"保鲜盒": "保鮮盒",
	"饭盒/提锅": "飯盒/提鍋",
	"调料器皿": "調料器皿",
	"厨房秤": "廚房秤",
	"烘培用具": "烘培用具",
	"厨房DIY/小工具": "廚房DIY/小工具",

	"火机烟具":"火機煙具",
	"煤油打火机": "煤油打火機",
	"一次性打火机": "一次性打火機",
	"火机油": "火機油",
	"充电打火机": "充電打火機",
	"烟嘴": "煙嘴",
	"烟斗": "煙斗",
	"雪茄用品": "雪茄用品",
	"戒烟贴": "戒煙貼",
	"烟灰缸": "煙灰缸",

	"茶具/咖啡具":"茶具/咖啡具",
	"茶杯": "茶杯",
	"茶壶": "茶壺",
	"茶具配件": "茶具配件",
	"茶盘": "茶盤",
	"咖啡杯": "咖啡杯",
	"旅行茶具": "旅行茶具",
	"咖啡具配件": "咖啡具配件",

	"刀剪菜板":"刀剪菜板",
	"砧板": "砧板",
	"菜刀": "菜刀",
	"瓜果刀/刨": "瓜果刀/刨",
	"剪刀": "剪刀",
	"多功能到": "多功能刀",

	"地毯底垫":"地毯底墊",
	"浴室地垫": "浴室地墊",
	"入户地垫": "入戶地墊",
	"商用地毯": "商用地毯",
	"床边毯": "床邊毯",
	"厨房地垫": "廚房地墊",
	"客厅地毯": "客廳地毯",

	"收纳用品":"收納用品",
	"收纳洞洞板": "收納洞洞板",
	"首饰盒": "首飾盒",
	"夹缝收纳柜": "夾縫收納柜",
	"化妆品收纳盒": "化妝品收納盒",
	"搬家纸箱": "搬家紙箱",
	"收纳箱": "收納箱",
	"鞋盒": "鞋盒",
	"搬家袋": "搬家袋",
	"桌面收纳盒": "桌面收納盒",
	"内衣收纳盒": "內衣收納盒",
	"真空收纳袋": "真空收納袋",
	"旅行收纳袋": "旅行收納袋",
	"化妆品收纳包": "化妝品收納包",
	"手办收纳盒": "手办收納盒",
	"收纳柜": "收納柜",

	"居家布艺":"居家布藝",
	"毛巾浴巾": "毛巾浴巾",
	"沙发垫": "沙發墊",
	"门帘": "門簾",
	"一次性洗脸巾": "一次性洗臉巾",
	"飘窗垫": "飄窗墊",
	"干发帽": "乾發帽",
	"窗纱": "窗紗",
	"蒲团": "蒲團",
	"毛毯": "毯子",
	"窗帘": "窗簾",
	"抱枕靠垫": "抱枕靠墊",
	"桌布": "桌布",

	"床上用品":"床上用品",
	"枕头": "枕頭",
	"床垫床褥": "床墊床褥",
	"床单床笠": "床單床笠",
	"蚊帐": "蚊帳",
	"棉花被": "棉花被",
	"四件套": "四件套",
	"纤维被": "纖維被",
	"蚕丝被": "蠶絲被",
	"羽绒服被": "羽絨服被",
	"电热毯": "電熱毯",
	"凉席": "涼席",
	"羊毛被": "羊毛被",
	"水暖毯": "水暖毯",
	"凉感被": "涼感被",


	"家电":"家電",


	"环境电器":"環境電器",
	"电风扇": "電風扇",
	"净水器": "淨水器",
	"空气净化器": "空氣淨化器",
	"茶吧机": "茶吧機",
	"除湿机": "除濕機",
	"取暖器": "取暖器",
	"电暖桌": "電暖桌",
	"电油灯": "電油燈",
	"软水机": "軟水機",
	"即热饮水机": "即熱飲水機",
	"加热净水器": "加熱淨水器",
	"全屋净水": "全屋淨水器",
	"加湿净化器": "加濕淨化器",
	"无雾加湿器": "無霧加濕器",
	"移动地暖": "移動地暖",

	"空调":"空調",
	"变频空调": "變頻空調",
	"新风空调": "新風空調",
	"新一级能效": "新一級能效",
	"空调柜机": "空調櫃機",
	"空调挂机": "空調掛機",
	"中央空调": "中央空調",

	"冰箱":"冰箱",
	"多门冰箱": "多門冰箱",
	"对开门冰箱": "對開門冰箱",
	"十字对开门": "十字對開門冰箱",
	"双门冰箱": "雙門冰箱",
	"单门冰箱": "單門冰箱",
	"三门冰箱": "三門冰箱",
	"冷柜/冰吧": "冷櫃/冰吧",
	"红酒柜": "紅酒櫃",

	"洗衣机":"洗衣機",
	"洗烘一体机": "洗烘一體機",
	"滚筒洗衣机": "滾筒洗衣機",
	"迷你洗衣机": "迷你洗衣機",
	"烘干机": "烘乾機",

	"电视":"電視",
	"K歌电视": "K歌電視",
	"艺术电视": "藝術電視",
	"会议电视": "會議電視",
	"游戏电视": "遊戲電視",
	"85吋电视": "85吋電視",
	"98吋电视": "98吋電視",
	"75吋电视": "75吋電視",
	"miniled电视": "迷你LED電視",
	"电视挂架": "電視掛架",
	"电视推车": "電視推車",
	"电视遥控器": "電視遙控器",

	"厨房小电":"廚房小電",
	"电饭煲": "電飯煲",
	"电水壶热水瓶": "電水壺熱水瓶",
	"电磁炉": "電磁爐",
	"破壁机": "破壁機",
	"电压力锅": "電壓力鍋",
	"养生壶": "養生壺",
	"电饼档": "電餅檔",
	"微波炉": "微波爐",
	"空气炸锅": "空氣炸鍋",
	"咖啡机": "咖啡機",
	"电烤箱": "電烤箱",
	"三明治早餐机": "三明治早餐機",
	"果蔬净化器": "果蔬淨化器",
	"炒菜机器人": "炒菜機器人",
	"电动榨汁杯": "電動榨汁杯",
	"电煮锅": "電煮鍋",
	"刀筷消毒机": "刀筷消毒機",
	"电热火锅": "電熱火鍋",
	"电蒸锅": "電蒸鍋",
	"厨师机": "厨师機",
	"绞肉机": "絞肉機",

	"厨卫大电":"廚衛大電",
	"油烟机": "油煙機",
	"灶具": "竈具",
	"电热水具": "電熱水具",
	"燃气热水器": "燃氣熱水器",
	"消毒柜": "消毒櫃",
	"洗碗机": "洗碗機",
	"壁挂炉": "壁挂炉",
	"空气能热水器": "空氣能熱水器",
	"太阳能热水器": "太陽能熱水器",
	"嵌入式厨电": "嵌入式廚電",
	"电热水龙头": "電熱水龍頭",
	"集成水槽": "集成水槽",
	"集成灶": "集成灶",

	"个护健康":"個護健康",
	"干衣机": "乾衣機",
	"剃须刀": "剃鬚刀",
	"按摩器": "按摩器",
	"电吹风": "電吹風",
	"电动牙叔": "電動牙刷",
	"理发器": "理髮器",
	"美容器": "美容儀",
	"卷/直发器": "卷/直髮器",
	"冲牙器": "沖牙器",
	"按摩椅": "按摩椅",
	"足浴盆": "足浴盆",
	"剃/脱毛器": "剃/脫毛器",
	"潮流护理电器": "潮流護理電器",
	"洗手机": "洗手機",
	"手持挂烫机": "手持掛燙機",

	"家电配件":"家電配件",
	"燃气灶支架": "燃氣竈支架",
	"个护健康配件": "個護健康配件",
	"电视支架": "電視支架",
	"冰箱配件": "冰箱配件",
	"电视艺术支架": "電視藝術支架",
	"吸尘器支架": "吸塵器支架",
	"热水器镁棒": "熱水器鎂棒",
	"聚水罩": "聚水罩",
	"冰箱门封条": "冰箱門封條",
	"生活电器配件": "生活電器配件",
	"洗衣机配件": "洗衣機配件",
	"空调挡风板": "空調擋風板",
	"电视配件": "電視配件",
	"空调遥控器": "空調遙控器",
	"空调配件": "空調配件",
	"冰箱底座架": "冰箱底座架",
	"洗衣机底座": "洗衣機底座",
	"空调支架": "空調支架",
	"厨房小电配件": "廚房小電配件",
	"厨卫大电配件": "廚衛大電配件",
	"洗衣机排水管": "洗衣機排水管",

	"清洁电器":"清潔電器",
	"扫地机器人": "掃地機器人",
	"蒸汽电动拖把": "蒸汽電動拖把",
	"除螨仪": "除螨儀",
	"擦窗机器人": "擦窗機器人",
	"洗地机": "洗地機",

	"视听影音":"視聽影音",
	"麦克风": "麥克風",
	"KTV音响": "KTV音響",
	"家庭影响": "家庭音響",
	"功放": "功放",
	"迷你音响": "迷你音響",
	"电视音响": "電視音響",


	"家居家装":"家居家裝",


	"五金工具":"五金工具",
	"家用五金": "家用五金",
	"电钻": "電鑽",
	"螺丝刀": "螺絲刀",
	"增压泵": "增壓泵",
	"水平仪": "水平儀",
	"工具套装": "工具套裝",
	"电锯": "電鋸",
	"角磨机": "角磨機",
	"测距仪": "測距儀",
	"五金工具箱": "五金工具箱",
	"水平尺": "水平尺",
	"家用电梯": "家用電梯",
	"购物车": "購物車",
	"机械锁": "機械鎖",
	"智能锁": "智能鎖",

	"电工电料":"電工電料",
	"开关插座": "開關插座",
	"智能家居": "智能家居",
	"电料配件": "電料配件",
	"智能开关": "智能開關",
	"电线/电缆": "電線/電纜",
	"配电箱/断路器": "配電箱/斷路器",
	"电气控制": "電氣控制",
	"智能窗帘": "智能窗簾",

	"书房家具":"書房家具",
	"电脑桌": "電脳桌",
	"电脑椅": "電脳椅",
	"书架": "書架",
	"书桌": "書桌",
	"升降桌": "升降桌",
	"红木书桌": "紅木書桌",

	"餐厅家具":"餐廳家具",
	"岩板餐桌": "巖板餐桌",
	"实木餐桌": "實木餐桌",
	"岛台餐桌": "島臺餐桌",
	"折叠餐桌": "折疊餐桌",
	"餐车": "餐車",
	"餐边柜": "餐邊柜",
	"餐椅": "餐椅",
	"红木餐桌": "紅木餐桌",
	"红木餐边桌": "紅木餐邊桌",

	"阳台储物":"陽台儲物",
	"飘窗桌": "飄窗桌",
	"层架/置物架": "層架/置物架",
	"智能晾衣架": "智能晾衣架",
	"阳台柜": "陽台柜",
	"衣帽架": "衣帽架",
	"落地晾衣架": "落地晾衣架",
	"隐藏晾衣架": "隱藏晾衣架",
	"鞋架": "鞋架",
	"储物/收纳": "儲物/收納",
	"手摇晾衣架": "手搖晾衣架",
	"墙面搁架": "牆面搁架",
	"泳池躺椅": "泳池躺椅",

	"厨房卫浴":"廚房衛浴",
	"厨房配件": "廚房配件",
	"淋浴花洒": "淋浴花灑",
	"龙头": "龍頭",
	"厨卫挂件": "廚衛掛件",
	"水槽": "水槽",
	"马桶": "馬桶",
	"陶瓷件组套": "陶瓷件組套裝",
	"浴室柜": "浴室柜",
	"浴霸": "浴霸",
	"净水软水": "淨水軟水",
	"淋浴房": "淋浴房",
	"智能马桶盖": "智能馬桶圈",
	"浴缸": "浴缸",
	"沐浴桶": "沐浴桶",
	"垃圾处理器": "垃圾處理器",

	"客厅家具":"客廳家具",
	"边桌/茶几": "邊桌/茶几",
	"布艺沙发": "布藝沙發",
	"皮艺沙发": "皮藝沙發",
	"鞋柜": "鞋柜",
	"沙发床": "沙發床",
	"电视柜": "電視柜",
	"穿衣镜": "穿衣鏡",
	"实木沙发": "實木沙發",
	"屏风": "屏風",
	"懒人沙发": "懶人沙發",
	"功能沙发": "功能沙發",
	"酒柜": "酒柜",
	"红木沙发": "紅木沙發",
	"壁炉": "壁爐",
	"红木电视柜": "紅木電視柜",

	"庭院户外":"庭院戶外",
	"折叠穿": "折叠穿",
	"遮阳伞": "遮陽傘",
	"充气家具": "充氣家具",
	"遮阳棚": "遮陽棚",
	"家用梯": "家用梯",
	"户外桌椅": "戶外桌椅",
	"木屋凉亭": "木屋涼亭",
	"秋千": "秋千",
	"岗亭岗台": "崗亭崗臺",
	"户外沙发": "戶外沙發",
	"吊篮": "吊籃",

	"卧室家具":"臥室家具",
	"实木床": "實木床",
	"皮艺床": "皮藝床",
	"衣柜": "衣柜",
	"实木衣柜": "實木衣柜",
	"床头柜": "床頭柜",
	"床垫": "床墊",
	"乳胶床垫": "乳膠床墊",
	"弹簧床垫": "彈簧床墊",
	"简易衣柜": "簡易衣柜",
	"斗柜": "斗柜",
	"布艺床": "布藝床",
	"床尾凳": "床尾凳",
	"红木床": "紅木床",
	"红木衣柜": "紅木衣柜",
	"梳妆台/凳": "梳妝臺/凳",

	"儿童家具":"兒童家具",
	"高低床": "雙層床",
	"绘本架": "繪本架",
	"儿童床": "兒童床",
	"边床": "邊床",
	"儿童桌椅套装": "兒童桌椅套裝",
	"儿童椅": "兒童椅",
	"儿童床垫": "兒童床墊",
	"儿童桌": "兒童桌",
	"儿童书桌": "兒童書桌",
	"儿童衣柜": "兒童衣柜",
	"儿童沙发": "兒童沙發",
	"儿童收纳储物": "兒童收納儲物",
	"儿童凳": "兒童凳",
	"儿童玩具桌": "兒童玩具桌",

	"商业办公":"商業辦公",
	"适老家具": "適老家具",
	"餐饮家具": "餐飲家具",
	"会议桌": "會議桌",
	"货架": "貨架",
	"办公椅": "辦公椅",
	"文件柜": "文件柜",

	"灯饰照明":"燈飾照明",
	"吸顶灯": "吸頂燈",
	"台灯": "台燈",
	"吊灯": "吊燈",
	"壁灯": "壁燈",
	"镜前灯": "鏡前燈",
	"落地灯": "落地燈",
	"氛围照明": "氛圍照明",
	"杀菌灯": "殺菌燈",
	

	"母婴":"母嬰",


	"奶粉":"奶粉",
	"特殊配方奶粉": "特殊配方奶粉",
	"有机奶粉": "有機奶粉",
	"1段": "1段",
	"2段": "2段",
	"3段": "3段",
	"4段": "4段",
	"羊奶粉": "羊奶粉",
	"孕妈奶粉": "孕媽奶粉",

	"尿布湿巾":"尿布濕巾",
	"拉拉裤": "拉拉褲",
	"成人尿裤": "成人尿布",
	"婴儿湿巾": "嬰兒濕巾",

	"喂养工具":"餵養工具",
	"奶瓶奶嘴": "奶瓶奶嘴",
	"水壶/水杯": "水壺/水杯",
	"儿童餐具": "兒童餐具",
	"暖奶调奶": "暖奶調奶",
	"吸奶器": "吸奶器",
	"牙胶安抚": "牙膠安撫",
	"辅食料理机": "輔食料理機",
	"食物存储": "食物儲存",
	"消毒锅/柜": "消毒鍋/櫃",

	"洗护用品":"洗護用品",
	"宝宝护肤": "寶寶護膚",
	"驱蚊防晒": "驅蚊防曬",
	"洗发沐浴": "洗髮沐浴",
	"婴儿洗鼻器": "嬰兒洗鼻器",
	"日常护理": "日常護理",
	"口腔清洁": "口腔清潔",
	"洗澡用具": "洗澡用具",
	"棉柔巾": "棉柔縉",
	"洗衣液/皂": "洗衣液/皂",
	"坐便器": "坐便器",
	"奶瓶清洗": "奶瓶清洗",
	"婴儿理发器": "嬰兒理髮器",

	"营养辅食":"營養輔食",
	"宝宝零食": "寶寶零食",
	"有机辅食": "有機輔食",
	"米粉/菜粉": "米粉/菜粉",
	"面条/粥": "麵條/粥",
	"果泥/果汁": "果泥/果汁",
	"清火/开胃": "清火/開胃",
	"DHA": "DHA",
	"益生菌/初乳": "益生菌/初乳",
	"钙铁锌/维生素": "鈣鐵鋅/維生素",


	"玩具":"玩具",


	"潮流玩具/毛绒":"潮流玩具/毛絨",
	"卡通周边": "卡通周邊",
	"毛绒玩具": "毛絨玩具",
	"盲盒": "盲盒",
	"网游周边": "網遊週邊",
	"变形模型": "變形模型",
	"电影周边": "電影周邊",
	"人偶/BJD/兵人": "人偶/BJD/兵人",
	"陀螺": "陀螺",
	"靠垫抱枕": "靠墊抱枕",
	"悠悠球/溜溜球": "悠悠球/溜溜球",
	"扭蛋": "扭蛋",

	"创意减压":"創意減壓",
	"减压整蛊": "減壓整蠱",
	"魔术道具": "魔術道具",
	"怀旧玩具": "懷舊玩具",
	"转转笔": "轉轉筆",
	"指尖陀螺": "指尖陀螺",
	"解锁玩具": "解鎖玩具",

	"早教益智":"早教益智",
	"早教机/故事机": "早教機/故事機",
	"积木": "積木",
	"过家家玩具": "過家家玩具",
	"拼图": "拼圖",
	"绘画工具": "繪畫工具",
	"爬行垫/毯": "爬行墊/毯",
	"魔方": "魔方",
	"桌面玩具": "桌面玩具",
	"STEAM玩具": "STEAM玩具",
	"仿真动物玩具": "仿真動物玩具",
	"摇铃/床铃": "搖鈴/床鈴",
	"磁力片": "磁力片",
	"健身架": "健身架",
	"敲打玩具": "敲打玩具",
	"点读笔": "點讀筆",

	"遥控/玩具":"遙控/玩具",
	"车模": "車模",
	"软弹枪": "軟彈槍",
	"遥控车": "遙控車",
	"遥控飞机": "遙控飛機",
	"轨道车": "軌道車",
	"飞机模型": "飛機模型",
	"遥控船": "遙控船",
	"机器人": "機器人",

	"运动划水":"運動划水",
	"洗澡玩具": "洗澡玩具",
	"泡泡枪": "泡泡槍",
	"玩沙玩具": "沙灘玩具",
	"电动玩具枪": "電動玩具槍",
	"摇马": "搖馬",
	"儿童滑梯": "兒童滑梯",
	"炫舞毯": "炫舞毯",

	"特色类目":"特色類目",
	"手办": "手辦",


	"文具":"文具",


	"笔类书写工具":"筆類書寫工具",
	"记号笔": "記號筆",
	"中性笔": "中性筆",
	"钢笔": "鋼筆",
	"铅笔": "鉛筆",
	"笔芯/替芯": "筆芯/替芯",
	"墨囊/墨水": "墨囊/墨水",
	"荧光笔": "螢光筆",
	"圆珠笔": "圓珠筆",
	"白板笔": "白板筆",
	"宝珠笔": "原子筆",
	"针管笔": "鉛筆",
	"油漆笔": "油漆筆",
	"秀丽笔": "秀麗筆",

	"本册/印刷品":"本冊/印刷品",
	"笔记本记事本": "筆記本/記事本",
	"作业本": "作業本",
	"便利贴": "便利貼",
	"书签": "書籤",
	"标签贴纸": "標籤貼紙",
	"手账": "手賬/手帳",
	"文稿纸草稿纸": "文稿紙草稿紙",
	"相册": "相冊",
	"信封/红包": "信封/紅包",
	"证书签到册": "證書簽到冊",
	"活页替芯": "活頁替芯",
	"贺卡/明信片": "賀卡/明信片",
	"日程本计划本": "日程本/計畫本",
	"日历/台历": "日曆/台曆",
	"日记本": "日記本",
	"信纸": "信紙",
	"冰箱贴防撞贴": "冰箱貼防撞貼",
	"宣传单/海报": "宣傳單/海報",
	"同学录纪念册": "同學錄紀念冊",
	"拍纸本": "紙製筆記本",
	"广告纸爆炸贴": "廣告紙爆炸貼",
	"分页纸索引纸": "分頁紙索引紙",
	"奖状": "獎狀",
	"教学用本": "教學用本",
	"立体便签拼图": "3D貼紙拼圖",
	"电话本通讯录": "電話簿",

	"学习用品":"學習用品",
	"练字帖": "練字帖",
	"文具盒/笔袋": "文具盒/筆袋",
	"橡皮擦": "橡皮擦",
	"修正带": "修正帶",
	"卷笔刀卷比器": "卷筆刀",
	"贴纸": "貼紙",
	"补习袋": "補習袋",
	"书皮/书套": "書皮/書套",
	"坐姿修正器": "坐姿修正器",
	"阅读器": "閱讀器",
	"姓名贴": "姓名貼",
	"握笔器": "握筆器",
	"自律打卡器": "自律打卡器",
	"学生计时器": "學生計時器",
	"学生书桌垫": "學生書桌墊",
	"儿童卡通印章": "兒童卡通印章",
	"修正液": "修正液",
	"笔套/笔帽": "筆套/筆帽",
	"姓名印章": "姓名印章",
	"桌面吸尘器": "桌面吸塵器",
	"答题卡/纸": "答題卡/紙",
	"挂书袋": "懸掛式書包",
	"红领巾": "紅領巾",
	"词卡/图卡": "詞卡/圖卡",
	"放大镜": "放大鏡",
	"拼音机口算机": "拼音機口算機",
	"学习护指绷带": "學習護紙繃帶",
	"少先队徽": "少先隊徽",
	"压花器": "壓花機",
	"修正贴": "修正貼",
	"少先队标志": "少先隊標誌",

	"财会用品":"財會用品",
	"凭证/单据": "憑證/單據",
	"印章/印油": "印章/印油",
	"账本/账册": "賬本/帳冊",
	"橡皮筋": "橡皮筋",
	"号码机": "號碼印字機",
	"印章盒/箱": "印章盒/箱",
	"复写纸": "複寫紙",
	"支票夹": "支票夾",
	"支票打印机": "支票打印機",
	"湿手器": "濕手器",
	"财会软件": "財會軟件",
	"手提金库": "便攜式保險箱",

	"其他文具用品":"其他文具用品",
	"旗帜": "旗幟",
	"桌牌": "桌牌",
	"usb风扇": "usb風扇",
	"徽章": "徽章",
	"奖杯奖牌": "獎杯獎牌",
	"温湿度计": "溫濕度計",
	"会议指示牌": "會議指示牌",
	"许愿瓶": "許願瓶",

	"美术用品":"美術用品",
	"美术颜料": "美術顏料",
	"马克笔": "馬克筆",
	"画纸/画本": "畫紙/畫本",
	"素描铅笔碳棒": "素描鉛筆碳棒",
	"水彩笔": "水彩筆",
	"颜料画笔": "顏料畫筆",
	"画架/画板": "畫架/畫板",
	"彩色铅笔": "彩色鉛筆",
	"油画棒": "油畫棒",
	"调色盘/纸/盒": "調色盤/紙/盒",
	"画布/画框": "畫布/畫框",
	"画袋/画包": "畫袋/畫包",
	"勾线笔": "勾線筆",
	"蜡笔": "蠟筆",
	"揉擦笔揉擦棉": "揉擦筆揉擦棉",
	"石膏雕塑": "石膏雕塑",
	"色卡": "色卡",
	"拷贝台临摹台": "拷貝台臨摹台",
	"油画刀/刮刀": "油畫刀/刮刀",
	"粉彩棒色粉笔": "粉彩棒色粉筆",
	"刮画纸": "刮畫紙",
	"写生车/画车": "寫生車/畫車",
	"风干/速干器": "風乾/速乾器",
	"绘画油壶": "繪畫油壺",
	"喷绘/取色笔": "噴繪/取色筆",
	"画杖": "畫杖",

	"裁剪工具":"裁剪工具",
	"美工刀": "美工刀",
	"剪刀": "剪刀",
	"裁纸刀": "裁紙刀",
	"开箱器": "開箱器",
	"启信刀拆信刀": "開信刀",

	"文房四宝":"文房四寶",
	"宣纸/制品": "宣紙/製品",
	"毛笔": "毛筆",
	"文房配件": "文房配件",
	"墨汁/墨锭": "墨汁/墨錠",
	"文房套装": "文房套裝",
	"毛笔字帖": "毛筆字帖",
	"砚台": "硯台",
	"书画毛毡": "書畫毛氈",
	"镇尺/镇纸": "鎮尺/鎮紙",
	"水写布": "水寫布",
	"石章": "石章",
	"文房印泥": "寫字台墨水墊",
	"篆刻工具": "篆刻工具",

	"收纳用品":"收納用品",
	"卡套/证件套": "卡套/證件套",
	"资料册": "資料冊",
	"夹板": "夾板",
	"档案/资料盒": "檔案/資料盒",
	"文件袋": "文件袋",
	"文件筐": "文件筐",
	"笔筒": "筆筒",
	"文件套": "文件套",
	"抽杆/报告夹": "抽桿/報告夾",
	"书立": "書立",
	"档案袋": "公文包",
	"科目袋": "科目袋",
	"风琴包/袋": "風琴包/袋",
	"事务包": "事務包",
	"钥匙管理": "鑰匙管理",
	"意见/抽奖箱": "意見/抽獎箱",
	"书报/展示架": "書報/展示架",
	"证件框": "證件盒",
	"快劳夹": "快勞夾",
	"孔装夹": "孔裝夾",
	"办公纸箱": "辦公紙箱",
	"桌面文件柜": "桌面文件櫃",
	"活页袋": "活頁袋",
	"名片盒": "名片盒",
	"名片册": "名片冊",
	"边角夹": "邊角夾",
	"报纸夹": "報紙夾",
	"吊挂夹": "懸掛夾",

	"胶粘用品":"膠粘用品",
	"透明胶带": "透明膠帶",
	"胶水": "膠水",
	"双面胶带": "雙面膠帶",
	"固体胶": "固體膠",
	"纳米胶带": "納米膠帶",
	"美纹纸胶带": "美紋紙膠帶",
	"布基胶带": "布基膠帶",
	"点点胶": "點點膠",
	"警示胶带": "警示膠帶",
	"绝缘胶带": "絕緣膠帶",
	"铝箔胶带": "鋁箔膠帶",
	"牛皮纸": "牛皮紙",
	"封箱器": "封箱器",
	"学生白胶": "學生白膠",
	"隐形胶带": "隱形膠帶",
	"胶带座": "膠帶座",
	"浆糊": "漿糊",

	"绘图计算用品":"繪圖計算用品",
	"计算器": "計算機",
	"直尺": "直尺",
	"测绘套装": "測繪套裝",
	"圆规": "圓規",
	"皮卷尺/软尺": "皮卷尺/軟尺",
	"钢卷尺": "鋼卷尺",
	"三角尺": "三角尺",
	"算盘": "算盤",
	"手抄报模板": "手抄報模板",
	"绘图模板": "繪圖模板",
	"比例尺": "比例尺",
	"量角器": "量角器",
	"丁字尺": "丁字尺",

	"装订用品":"裝訂用品",
	"票夹/长尾夹": "票夾/長尾夾",
	"磁扣/磁钉": "磁扣/磁釘",
	"订书机": "釘書機",
	"打孔机": "打孔機",
	"回形针": "回形針",
	"推夹器": "推夾器",
	"别针": "別針",
	"大头针": "大頭針",
	"起钉器": "起釘器",
	"图钉": "圖釘",
	"三针一钉": "三針一釘",

	"教学实验用品":"教學實驗用品",
	"数学教具": "教學用具",
	"地球仪": "地球儀",
	"教鞭/手指棒": "教鞭/手指棒",
	"粉笔": "粉筆",
	"黑板": "黑板",
	"板擦": "板擦",
	"教学模型": "教學模型",
	"教学刻章": "教學刻章",
	"教学标本": "教學標本",
	"显微镜": "顯微鏡",

	"手工制作":"手工/DIY",
	"彩泥/粘土": "彩泥/粘土",
	"折纸/DIY": "折紙/DIY",
	"咕卡套装": "咕卡套裝",
	"火漆印章": "火漆印章",
	"3D打印笔": "3D打印筆",
	"剪纸/DIY": "剪紙/DIY",
	"奖励刮刮卡": "獎勵刮刮卡",
	"礼品纸": "禮品紙",
	"纸雕": "紙雕",
	"衍纸": "衍紙",


	"电脑办公":"電腦辦公",


	"办公用纸":"辦公用紙",
	"工程纸": "工程紙",
	"其他标签纸": "其他標籤紙",
	"彩激纸": "彩色激光紙",
	"其他纸类": "其他紙類",
	"传真纸": "傳真紙",
	"卡纸": "卡紙",
	"铜版纸": "銅板紙",
	"条码标签纸": "條碼標籤紙",
	"相片纸": "相片紙",
	"复印纸": "複印紙",
	"收银纸": "收銀紙",

	"外设产品":"外設產品",
	"手机U盘": "移動U盤",
	"线缆": "線纜",
	"键盘配件": "鍵盤配件",
	"平板电脑配件": "平板電腦配件",
	"机械键盘": "機械鍵盤",
	"插座": "插座",
	"笔记本配件": "筆記本配件",
	"鼠标": "鼠標",
	"鼠标垫": "鼠標墊",
	"U盘": "U盤",
	"扩展坞": "擴展塢",
	"投屏器": "投屏器",
	"移动硬盘": "移動硬盤",
	"移动固态硬盘": "移動固態硬盤",
	"硬盘盒": "硬盤盒",
	"UPS电源": "UPS電源",
	"摄像头": "攝像頭",
	"手写板": "手寫板",
	"网络仪表": "網絡儀表",
	"机柜": "機櫃",
	"电脑工具": "電腦工具",
	"电脑清洁": "電腦清潔",

	"电脑整机":"電腦整機",
	"闺蜜机": "閨蜜機",
	"轻薄本": "輕薄筆記本",
	"二合一平板": "二合一平板",
	"游戏本": "遊戲本",
	"商用台式机": "商用桌上型電腦",
	"游戏台式机": "遊戲桌上型電腦",
	"台式机": "桌上型電腦",
	"一体机": "一體機",
	"服务器/工作站": "服務器/工作站",

	"耗材":"耗材",
	"硒鼓": "硒鼓",
	"墨盒": "墨盒",
	"色带": "色帶",
	"纸类": "紙類",
	"刻录光盘": "刻錄光盤",
	"胶带": "膠帶",

	"电脑组件":"電腦組件",
	"显示器": "顯示器",
	"显示器配件": "顯示器配件",
	"SSD固态硬盘": "SSD固態硬盤",
	"散热器": "散熱器",
	"内存": "內存",
	"显卡": "顯卡",
	"机箱": "底盤",
	"组装电脑": "組裝電腦",
	"电源": "電源",
	"主板": "主板",
	"硬盘": "硬盤",
	"装机配件": "裝機配件",
	"CPU": "中央處理器",
	"刻录机/光驱": "刻錄機/光驅",
	"声卡/扩展卡": "聲卡/擴展卡",

	"办公设备":"辦公設備",
	"打印机": "打印機",
	"投影机": "投影儀",
	"投影配件": "投影配件",
	"家用打印机": "家用打印機",
	"传真设备": "傳真設備",
	"验钞/点钞机": "驗鈔/點鈔機",
	"扫描仪": "掃描儀",
	"条码扫描/采集器": "條碼掃描/採集器",
	"复合机": "複合機",
	"碎纸机": "碎紙機",
	"考勤门禁": "考勤門禁",
	"收银机": "收銀機",
	"激光笔": "激光筆",
	"保险柜/箱": "保險櫃/箱",
	"打码机": "打碼機",
	"安防监控": "安防監控",
	"会议音频视频": "會議音頻視頻",
	"写字板/支架": "寫字板/支架",
	"办公大屏": "辦公大屏",
	"会议平板": "會議平板",
	"广告机": "廣告機",

	"网络产品":"網絡產品",
	"路由器": "路由器",
	"网线": "網線",
	"网络配件": "網絡配件",
	"交换机": "交換機",
	"5G/4G上网": "5G/4G上網",
	"网卡": "網卡",
	"网络盒子": "網絡盒子",
	"网络存储": "網絡儲存",

	"游戏设备":"遊戲設備",
	"手柄/方向盘": "手柄/方向盤",
	"游戏软件": "遊戲軟件",
	"游戏周边": "遊戲週邊",
	"游戏机": "遊戲機",
	"游戏耳机": "遊戲耳機",


	"数码":"數碼",


	"数码趋势":"數碼趨勢",
	"AI学习机": "AI學習機",
	"开放式耳机": "開放式耳機",
	"智能健康手表": "智能健康手錶",
	"Vlog相机": "Vlog相機",
	"AR眼镜": "AR眼鏡",
	"AI手表": "AI手錶",
	"AI眼镜": "AI眼鏡",
	"AI相机": "AI相機",
	"AI耳机": "AI耳機",
	"AI音箱": "AI音響",
	"AI门锁": "AI門鎖",
	"AI摄像头": "AI攝像頭",

	"影音娱乐":"影音娛樂",
	"直播设备": "直播設備",
	"居家艺术音箱": "居家藝術音箱",
	"HIFI音箱": "HIFI音箱",
	"降噪耳机": "降噪耳機",
	"耳夹式耳机": "耳夾式耳機",
	"运动耳机": "運動耳機",
	"眼镜耳机": "眼鏡耳機",
	"颈挂式耳机": "頸掛式耳機",
	"头戴式耳机": "頭戴式耳機",
	"骨传导耳机": "骨傳導耳機",
	"蓝牙耳机": "藍牙耳機",
	"乐器音箱": "樂器音箱",
	"舞台音箱": "舞台音箱",
	"拉杆/K歌": "拉桿/卡拉OK",
	"智能音箱": "智能音箱",
	"音箱/音响": "音箱/音響",
	"有线耳机": "有線耳機",
	"麦克风": "麥克風",
	"游戏耳机": "遊戲耳機",
	"MP3/MP4": "MP3/MP4",
	"专业音频": "專業音響",
	"音频线": "音頻線",
	"收音机": "收音機",

	"数码配件":"數碼配件",
	"usb风扇": "usb風扇",
	"智能手环配件": "智能手環配件",
	"手持稳定器": "手持穩定器",
	"镜头附件": "鏡頭附件",
	"智能手表配件": "智能手錶配件",
	"耳机配件": "耳機配件",
	"电池/充电器": "電池/充電器",
	"储存卡": "儲存卡",
	"机身附件": "機身附件",
	"相机包": "相機包",
	"三脚架/云台": "三腳架/雲台",
	"滤镜": "濾鏡",
	"运动相机配件": "運動相機配件",
	"读卡器": "讀卡器",
	"相机清洁/贴膜": "相機清潔/貼膜",
	"闪光灯/手柄": "閃光燈/手柄",

	"智能设备":"智能設備",
	"电话手表": "電話手錶",
	"XR设备": "XR設備",
	"智能戒指": "智能戒指",
	"监控摄像": "監控攝像",
	"智能家具": "智能家具",
	"智能儿童手表": "智能兒童手錶",
	"无人机": "無人機",
	"健康监测": "健康監測",
	"智能机器人": "智能機器人",
	"智能配饰": "智能配飾",
	"智能出行": "智能出行",
	"运动跟踪器": "運動跟蹤器",

	"摄影摄像":"攝影攝像",
	"数码相框": "數碼相框",
	"影棚器材": "影棚器材",
	"微单相机": "微單相機",
	"拍立得": "拍立得",
	"数码相机": "數碼相機",
	"单反相机": "單反相機",
	"冲印服务": "衝印服務",
	"摄像机": "攝像機",
	"户外器材": "戶外器材",

	"电子教育":"電子教育",
	"学习机": "學習機",
	"电纸书": "電子書",
	"电子词典": "電子辭典",
	"录音笔": "錄音筆",
	"点读机/笔": "點讀機/筆",
	"复读机": "復讀機",
	"翻译机": "翻譯機",
	"早教益智": "早教益智",


	"运动户外":"運動戶外",


	"骑行运动":"騎行運動",
	"穿戴装备": "穿戴裝備",
	"山地车": "山地車",
	"城市自行车": "城市自行車",
	"折叠车": "摺疊車",

	"户外装备":"戶外裝備",
	"旅游用品": "旅遊用品",
	"户外照明": "戶外照明",
	"便携桌椅床": "便攜式桌椅床",
	"帐篷/垫子": "帳篷/墊子",
	"冲浪潜水": "衝浪潛水",
	"野餐/烧烤": "野餐燒烤",
	"望远镜": "望遠鏡",
	"户外工具": "戶外工具",
	"军迷用品": "軍迷用品",
	"登山攀岩": "登山攀岩",
	"救援装备": "救援裝備",
	"睡袋/吊床": "睡袋/吊床",
	"户外仪表": "戶外儀器",
	"极限户外": "極限戶外",
	"滑雪装备": "滑雪裝備",

	"垂钓用具":"垂釣用具",
	"鱼饵": "魚餌",
	"钓鱼配件": "釣魚配件",
	"浮漂": "魚漂",
	"鱼线": "魚線",
	"鱼线轮": "捲線器",
	"钓箱钓椅": "釣箱釣椅",
	"渔具包": "漁具包",
	"钓鱼灯": "釣魚燈",
	"辅助装备": "輔助裝備",
	"钓竿": "釣竿",

	"体育用品":"體育用品",
	"羽毛球拍": "羽毛球拍",
	"棋牌麻将": "棋牌麻將",
	"篮球": "籃球",
	"乒乓球拍": "乒乓球拍",
	"轮滑滑板": "輪滑滑板",
	"足球": "足球",
	"网球拍": "網球拍",
	"田径鞋": "田徑鞋",
	"排球": "排球",
	"台球桌": "台球桌",
	"高尔夫球杆套杆": "高爾夫球桿套裝",
	"棒球": "棒球",

	"健身训练":"健身訓練",
	"哑铃": "啞鈴",
	"瑜伽用品": "瑜珈用品",
	"其他器械": "其他器械",
	"武术搏击": "武術搏擊",
	"跑步机": "跑步機",
	"动感单车": "動感單車",
	"甩脂机": "甩脂機",
	"综合训练器": "綜合訓練器",
	"椭圆机": "橢圓機",
	"仰卧板/收腹机": "仰臥板/健腹器",
	"踏步机": "踏步機",


	"箱包":"箱包",


	"功能箱包":"功能箱包",
	"旅行配件": "旅行配件",
	"护脊书包": "護脊書包",
	"行李箱": "行李箱",
	"儿童行李箱": "兒童行李箱",
	"儿童书包": "兒童書包",
	"旅行包": "旅行包",

	"潮流女包":"潮流女包",
	"单肩包": "單肩包",
	"迷你包": "迷你包",
	"手提包": "手提包",
	"女士皮带": "女士皮帶",
	"帆布包": "帆布包",
	"斜挎包": "斜跨包",

	"精品男包":"精品男包",
	"男士手机包": "男士手機包",
	"男士皮带": "男士皮帶",
	"单肩斜挎包": "單肩斜挎包",
	"男士双肩包": "男士雙肩包",
	"男士胸包": "男士胸包",
	"商务公文包": "商務公文包",


	"钟表珠宝":"鐘錶珠寶",


	"腕表":"腕錶",
	"国表": "國錶",
	"钟表配件": "鐘錶配件",
	"挂钟": "掛鐘",
	"闹钟": "鬧鐘",
	"智能手表": "智能手錶",
	"儿童手表": "兒童手錶",

	"饰品":"飾品",
	"手链/脚链": "手鍊/腳鏈",
	"项链": "項鏈",
	"耳饰": "耳飾",
	"饰品配件": "飾品配件",
	"时尚戒指": "時尚戒指",
	"胸针": "胸針",
	"发饰/发卡": "髮飾/髮卡",
	"水晶玛瑙": "水晶瑪瑙",
	

	"奢侈品":"奢侈品",


	"男包":"男包",

	"女包":"女包",

	"饰品":"飾品",

	"配饰":"配飾",


	"机械设备":"機械設備",


	"运输工具":"運輸工具",
	"三轮车": "三輪車",
	"货车": "貨車",
	"拖拉机": "拖拉機",
	"飞行器": "飛行器",

	"全部":"All",
}