export const lang = {
	"公司地址": "9o piso, edificio T1, Poly International Plaza, distrito de Chaoyang, Beijing, China.",
	"公司介绍": "Presentación",
	"品类介绍": "Categoría",
	"服务能力": "Capacidad de servicio",
	"需求反馈": "Retroalimentación de la demanda",
	"合作伙伴": "Socios",
	"联系我们": "Contáctanos",
	"全球优质供应链服务商": "Proveedor global de servicios de cadena de sumistro de alta calidad",
	"为全球品牌和企业提供定制化产品解决方案": "Proporcionar soluciones de productos personalizadas para marcas y empresas globales",
	"华汾公司介绍": "Introducción de Huafen (Beijing) International Trade Co., Ltd.",

	"company-profile-title1":"Huafen (Beijing) International Trade Co., Ltd. es",
	"company-profile1":"una empresa integral de comercio internacional que fusiona la investigación y el desarrollo de productos industriales, la producción, el diseño, la comercialización de marcas globales y las ventas de importación y exportación en una operación consistente. Nuestro enfoque único aprovecha la tecnología descentralizada Web 3.0 para proporcionar servicios de marketing en Internet y gestión digital de primer nivel. Nos destacamos en la preparación de estrategias digitales inteligentes a través de una red comercial global, brindando un valor excepcional a las marcas y empresas.",

	"company-profile-title2":"Ventaja estratégica: ",
	"company-profile2":"Aprovechamos las ventajas del transporte marítimo nacional y una sólida red publicitaria que abarca 89 ciudades y 90 aeropuertos, cubriendo efectivamente más del 90% de los puntos de referencia urbanos. Este amplio alcance nos permite conectarnos con la vasta población de China de 1.400 millones de personas, mejorando significativamente la visibilidad y el impacto de la marca.",

	"company-profile-title3":"Ofertas de productos innovadores: ",
	"company-profile3":"En Huafen, nos enorgullecemos de impulsar el avance tecnológico y la innovación. Introducimos productos de estilo de vida de alta calidad con importancia cultural internacional para los consumidores globales, mostrando el desarrollo comercial y las capacidades innovadoras de China en el escenario mundial.",

	"company-profile-title4":"Nuestra serie de productos:",
	"company-profile4":"Huafen gestiona tres series de productos distintas: ",

	"company-profile-title5":"1. Marcas propias:",
	"company-profile5":"marcas desarrolladas y propiedad de Huafen, que reflejan nuestro compromiso con la innovación y la calidad. ",

	"company-profile-title6":"2. Marcas autorizadas internacionalmente: ",
	"company-profile6":"marcas reconocidas a nivel mundial para las que tenemos derechos de distribución, lo que garantiza la calidad y la autenticidad. ",

	"company-profile-title7":"3. Marcas de agencia: ",
	"company-profile7":"marcas que representamos y comercializamos, aprovechando nuestra experiencia para expandir su alcance e impacto.",

	"company-profile-title8":"Cadena industrial integral: ",
	"company-profile8":"Nuestra empresa cuenta con una cadena industrial completa que integra investigación y desarrollo de productos, producción, ventas, logística y marketing. Este enfoque integrador garantiza operaciones sin interrupciones y un servicio excepcional, alineado con nuestro objetivo de ofrecer productos y soluciones superiores a nuestros clientes y socios en todo el mundo.",


	"我们能提供的品类": "Las categorías que podemos ofrecer",
	"个护清洁": "Cuidado personal y limpieza",
	"清洁工具": "Herramientas de limpieza",
	"家庭清洁": "Limpieza del hogar",
	"衣物清洁": "Limpieza de ropas",
	"洗发护发": "Lavado y cuidado del cabello",
	"身体护理": "Cuidado del cuerpo",
	"纸品湿巾": "Papeles y toallas húmedas",
	"口腔护理": "Cuidado bucal",
	"驱蚊驱虫": "Repelente de mosquitos e insectos",
	"食品酒饮": "Alimentos y bebidas",
	"乳饮冰品": "Leche y helados",
	"中外名酒": "Licores famosos chinos y extranjeros",
	"方便速食": "Comida conveniente",
	"粮油调味": "Cereales, aceites y condimentos",
	"咖啡": "Café",
	"休闲食品": "Snacks",
	"美妆护肤": "Maquillaje y cuidado de la piel",
	"面部护肤": "Cuidado facial",
	"香水": "Perfume",
	"彩妆": "Maquillaje",
	"家电": "Electrodomésticos",
	"家电配件": "Accesorios de electrodomésticos",
	"厨房小电": "Electrodomésticos de cocina",
	"清洁电器": "Electrodomésticos de limpieza",
	"个护健康": "Cuidado personal y salud",
	"空调": "Aire acondicionado",
	"冰箱": "Refrigerador",
	"电视": "Televisor",
	"厨卫大电": "Electrodomésticos de cocina y baño",
	"视听影音": "Audiovisuales",
	"家居厨具": "Utensilios de cocina",
	"火机烟具": "Encendedores y artículos para fumar",
	"餐具": "Vajillas",
	"烹饪厨具": "Utensilios de cocina",
	"水具酒具": "Utensilios de bebida",
	"生活日用": "Artículo de uso diario",
	"居家布艺": "Decoración de hogar",
	"床上用品": "Prendas de cama",
	"汽摩生活": "Accesorios de automóviles y motocicletas",
	"电动车": "Bicicleta eléctrica",
	"摩托车": "Motocicletas",
	"农用机械": "Maquinaria agrícola",
	"搬运设备": "Equipos de carga",
	"装饰用品": "Artículos de decoración",
	"派对用品": "Artículos para fiestas",
	"母婴": "Cuidado de madres y bebés",
	"奶粉": "Polvo de leche",
	"尿布湿巾": "Pañales y toallitas húmedas",
	"洗护用品": "Productos de higiene",
	"营养辅食": "Alimentos complementarios",
	"喂养工具": "Herramientas de alimentación",
	"欢迎访问 Go2Pie商城，查找商品": "Bienvenido a Go2Pie, buscar productos",
	"我们的服务能力": "Nuestras capacidades de servicio",
	"贸易": "Comercio",
	"自主工厂生产及国内外品牌直供": "Producción autónoma de fábricas y suministro directo de marcas nacionales e internacionales",
	"自研国际自由贸易平台Goπ": "Desarrolla autónomamente la plataforma internacional de comercio libre",
	"品牌授权及合作线下门店": "Autorización de marca y colaboración con tiendas físicas",
	"国内外各大品牌经销/代理商": "Distribuidores/Agentes de las principales marcas nacionales e internacionales",
	"国际线上交易支付管理系统": "Sistema de gestión de pagos para transacciones internacionales en línea",
	"营销": "Mercadotecnia",
	"整合营销推广解决方案": "Integrar soluciones de marketing y promoción",
	"媒体营销推广": "Promoción de marketing en medios",
	"社交平台运营": "Gestión de redes sociales",
	"电商平台运营": "Gestión de plataforma de comercio electrónico",
	"内容创意及制作": "Contenido creativo y producción",
	"物流配送": "Logística de distribución",
	"顶级物流体系及航运支持": "Sistema logístico de primer nivel y apoyo en transporte marítimo",
	"全国物流和冷链配送": "Logística nacional y distribución en cadena de frío",
	"国际供应及国际运输": "Suministro internacional y envío internacional",
	"提供销售通路搭建、企业集采及一件代发等全方位服务": "Proporcionar servicios integrales que incluyen la construcción de canales de venta, adquisiciones centralizadas para empresas y envíos directos por unidad",
	"需求反馈收集": "Recopilación de retroalimentación de necesidades",
	"我们可以满足您的所有需求，请留下您的需求，我们会及时与您联系": "Podemos satisfacer todas sus necesidades, por favor deje sus necesidades y nos pondremos en contacto con usted en breve",
	"提交需求反馈表单": "Enviar el formulario de retroalimentación de la demanda",
	"集团合作伙伴": "Socios del grupo",
	"集团": "grupo",
	"合作伙伴及品牌": "Socios y marcas",
	"合作媒体": "Medios de comunicación colaboradores",
	"华汾版权所有": "Todos los derechos reservados por Huafen",

	"收起": "Ver menos",
	"展开": "Ver todo",







	"食品":"Alimentos",


	"咖啡":"Café",
 	"胶囊咖啡": "Cápusula de café",
        "黑咖啡": "Café negro",
        "白咖啡": "Café blanco",
        "咖啡豆/粉": "Granos/polvo de café",
        "滴咖啡": "Café para maquina",
        "咖啡液": "Café líquido",
        "咖啡伴侣": "Coffee mate",
        "速溶咖啡": "Café instantáneo",
        "即饮咖啡": "Café listo para beber",
        "速溶黑咖啡": "Café negro instantáneo",
        "冻干咖啡": "Café liofilizado",
        "三倍的": "Triplicado",

	"可饮用谷物":"Cereales potable",
        "大米": "Cereal de arroz",
        "煎堆": "Bolas de sesamo",
        "奶茶": "Té con leche",
        "豆浆": "Leche de soja",
        "莲藕淀粉": "Almidón de raíz de loto",
        "全麦谷物": "Cereal de trigo integral",
        "谷物粉": "Harina de trigo",
        "水果麦片": "Cereal de fruta",
        "代餐摇床": "Sustituto alimenticio",
        "成人奶粉": "Leche en polvo para adultos",
        "霍尼": "Miel",

	"牛奶饮料和冰淇淋":"Bebidas lácteas y helados",
        "闪闪发光的水": "Agua carbonatada",
        "碳酸饮料": "Bebidas carbonatadas",
        "能量饮料": "Bebidas energéticas",
        "乳酸菌饮料": "Bebidas de bacterías de ácidos lácticos",
        "果蔬汁": "Jugo de frutas y verduras",
        "茶饮料": "Bebidas de té",
        "酸奶": "Yogurt",
        "纯牛奶": "Leche pura",
        "饮用水": "Agua",
        "冰淇淋": "Helado",

	"中外著名葡萄酒":"Vinos famosos chinos y extranjeros",
        "洋酒": "Vino extranjero",
        "白酒类": "Licor chino",
        "啤酒": "Cerveza",
        "葡萄酒": "Vino",
        "黄酒/保健酒": "Vino amarillo / vino de salud",

	"谷物和油调味品":"Cereales, condimentos y aceites",
        "中国调味品": "Condimentos chinos",
        "西餐": "Comida occidental",
        "配菜": "Guarniciones",
        "东南亚调味品": "Condimentos del sudeste asiático",
        "花生菜籽油": "Aceite de colza y maní",
        "葵花籽油": "Aceite de girasol",
        "米油": "Aceite de arroz",
        "橄榄油": "Aceite de oliva",
        "茶油": "Aceite de té",
        "谷物": "Cereales",
        "粉丝": "fideos",
        "面粉": "Harina",

	"南北干货":"Productos secos de China",
        "银耳": "Hongo Tremella",
        "扇贝": "Almejas",
        "干蔬菜": "Hortalizas secas",
        "真菌": "Hongos",
        "龙眼": "Longan",
        "莲子": "Semillas de loto",
        "干蘑菇": "Hongos secas",
        "干海产品": "Mariscos secos",
        "有机干货": "Comidas orgánicos secos",

	"方便快餐":"Comida rápida",
        "午餐肉": "Spam/ Carne enlatada",
        "自热食品": "Alimentos autocalentados",
        "米粉": "Fideos de arroz",
        "速溶汤": "Sopa instantánea",
        "火腿": "Jamón",
        "蜗牛米粉": "Fideos de arroz con caracol/ Luosifen",
        "方便面": "Fideos instantáneos",
        "粽子": "Tamal de arroz/ Zongzi",
        "青团": "Bolas de arroz glutinoso",

	"零食":"Bocadillos",
        "低热量零食": "Bocadillos bajos en calorías",
        "饼干和糕点": "Galletas y repostería",
        "愚蠢的": "Carne seca",
        "坚果和烤坚果": "Frutos secos y nueces tostadas",
        "蜜饯水果": "Frutas confitadas",
        "糖果": "Caramelos",
        "零食礼品包": "Combo de bocadillos",
        "麻辣豆腐干": "Tofu seco picante",
        "红烧鸡爪": "Patas de pollo estofadas",
        "薯片膨化": "Papitas fritas",
        "果冻布丁": "Pudín de gelatina",
        "巧克力": "Chocolate",

	"茶":"Té",
        "菊花茶": "Té de crisantemo",
        "绿茶": "Té verde",
        "红茶": "Té negro",
        "普洱茶": "Té Pu 'er",
        "乌龙茶": "Té oolong",
        "茉莉花茶": "Té de jazmín",
        "健康茶": "Té saludable",
        "白茶": "Té blanco",
        "西湖龙井": "Té West Lake Longjing",
        "铁观音": "Té Tieguanyin",


	"生鲜":"Fresco",


	"快速烘焙":"Comida rápida",
 	"方便面": "Fideos instantáneos",
        "粤式早茶": "Desayuno estilo cantonés",
        "烘焙半成品": "Alimentos precocidos",
        "面条": "Fideos",
        "饺子蒸饺和炸饺子": "Ravioles al vapor y fritos",
        "馄饨": "Wontón",
        "奶酪黄油": "Queso crema",
        "蛋糕和甜点": "Pasteles y postres",
        "汤圆元宵": "Albóndigas de arroz glutinoso",
        "披萨": "Pizza",
        "蛋挞": "Tarta de huevo",
        "低温粽子": "Zongzi congelados",

	"预先准备的菜肴":"Platos precocidos",
        "油炸食品": "Alimentos fritos",
        "便餐": "Comida rápida",
        "熟食": "Comida cocinada",
        "火锅配料": "Ingredientes para olla caliente",
        "培根香肠": "Salchichas de bacón",
        "半成品菜肴": "Platos precocidos",
        "海鲜配菜": "Guarniciones de mariscos",
        "当地特产": "Especialidades locales",
        "一个人": "Comida para 1 persona",

	"肉类和家禽产品":"Carne y productos avícolas",
        "冷肉": "Carne fría",
        "牛排": "Filete de res",
        "牛肉": "Carne de res",
        "鸡": "Pollo",
        "鸡蛋": "Huevos",
        "猪肉": "Cerdo",
        "羊肉": "Cordero",
        "鸭子": "Pato",

	"蔬菜":"Verduras",
        "大豆制品": "Productos de soja",
        "根状茎": "Rizomas",
        "红薯": "Boniatos",
        "有机时令蔬菜": "Verduras orgánicas de temporada",
        "玉米": "Maíz",
        "茄瓜": "Berenjena",
        "绿叶蔬菜": "Verduras de hoja verde",
        "洋葱、姜、大蒜和胡椒": "Cebolla, jengibre, ajo y pimienta",
        "冷冻蔬菜": "Verduras congeladas",
        "新鲜蘑菇": "Champiñones frescos",

	"海鲜":"Mariscos",
        "新鲜": "Fresco",
        "鲑鱼": "Salmón",
        "鱼": "Pescado",
        "小龙虾": "Langosta de Río",
        "小虾": "Camarón",
        "贝类": "Almejas",
        "大闸蟹": "Cangrejos peludos",
        "蟹": "Cangrejo",
        "海参": "Pepino del mar",

	"新鲜水果":"Frutas frescas",
        "进口水果": "Frutas importadas",
        "桃、李、杏": "Melocotón, ciruela y albaricoque",
        "苹果": "Manzana",
        "榴莲": "Durian",
        "芒果": "Mango",
        "梨": "Peras",
        "樱桃": "Cereza",
        "猕猴桃": "Kiwi",
        "荔枝": "Litchi",
        "蓝莓": "Arándanos",
        "橙色": "Naranja",
        "橙色/橘黄色": "Naranja / mandarina",
        "椰青": "Coco verde",
        "水果礼盒/礼券": "Combo de frutas / Cupón de combo",


	"个护清洁":"Limpieza y cuidado personal",


	"纸品湿巾":"Toallas y papeles sanitarios",
        "抽纸": "Servilletas",
        "手帕纸": "Pañuelos de papel",
        "卷纸": "Rollo de papel sanitario",
        "湿巾": "Toallitas húmedas",
        "厨房纸巾": "Toallas de papel cocina",

	"洗发护发":"Champú y acondicionador",
        "洗发水": "Champú",
        "护发素": "Acondicionador",
        "发膜": "Mascarilla para el cabello",
        "生发液": "Líquido de crecimiento del cabello",
        "护发精油": "Aceite para el cuidado del cabello",
        "洗护套装": "Combo de limpieza",

	"身体护理":"Cuidado del cuerpo",
        "精油": "Aceite esencial",
        "沐浴露": "Gel de baño",
        "男士沐浴": "Gel de baño para hombres",
        "儿童沐浴": "Gel de baño infantil",
        "香皂": "Jabón",
        "洗手液": "Jabón líquido",
        "美体/工具": "Utensilios para el cuerpo",
        "护手霜": "Crema para manos",
        "浴盐": "Sales de baño",
        "身体护理套": "Combo de cuidado corporal",
        "花露水": "Colonias",

	"口腔护理":"Higiene bucal",
        "牙膏": "Pasta de dientes",
        "儿童口腔": "Pasta de dientes infantil",
        "牙刷": "Cepillo de dientes",
        "漱口水": "Enjuague bucal",
        "牙线/牙线棒": "Hilo dental / palito dental",
        "口喷": "Spray bucal",
        "牙贴": "Pegatinas dentales",

	"衣物清洁":"Limpieza de ropa",
        "洗衣液": "Detergente líquido",
        "洗衣粉": "Detergente en polvo",
        "凝珠": "Cápsulas de detergente líquido",
        "洗衣皂": "Jabón de lavar",
        "柔顺剂": "Suavizante de ropas",
        "除菌液": "Líquido esterilizante",

	"女性护理":"Cuidado femenino",
        "卫生巾": "Íntimas",
        "护垫": "Almohadillas femeninas",
        "棉条": "Tanpong",
        "裤型卫生巾": "Pamper para adultos",
        "私处护理": "Gel íntimo",

	"驱蚊驱虫":"Repelente de mosquitos",
        "灭蚁": "Asesino de hormigas",
        "杀蟑": "Repelente de  cucarachas",
        "驱鼠": "Repelente para todo tipo de insectos",
        "除满": "Lleno",
        "驱蝇": "Repelente para moscas",

	"清洁工具":"Herramientas de limpieza",
        "拖把": "Trapeador",
        "垃圾桶": "Bote de basura",
        "垃圾袋": "Bolsa de basura",
        "清洁刷具": "Cepillo de limpieza",
        "抹布/百洁布": "Trapo y estropajos de limpieza",
        "手套/鞋套": "Guantes / fundas de zapatos",
        "一次性清洁用品": "Productos de limpieza desechables",
        "扫把": "Escoba",
        "脸盆/水桶": "Palangana / cubo",

	"发型设计":"Diseño de peinado",
        "发胶": "Gel para peinado",
        "发蜡/泥": "Ceragel",
        "定型啫喱": "Laca",
        "染发": "Tinte para el cabello",
        "假发": "Peluca",
        "弹力素": "Elastina",
        "美发工具": "Herramientas de peluquería",
        "蓬蓬粉": "Polvo suelto",

	"家庭清洁":"Limpieza del hogar",
        "油污净": "Removedor de grasa",
        "洗洁精": "Líquido para lavar platos",
        "洁厕剂": "Limpiador de inodoros",
        "消毒液": "Desinfectante",
        "地板清洁剂": "Limpiador de pisos",
        "除湿干燥剂": "Deshumidificador",

	"成人护理":"Cuidado de adultos",
        "成人纸尿裤": "Pañales para adultos",
        "成人拉拉裤": "Pañales para adultos elásticos",
        "成人护理垫": "Almohadilla absorvente de cuidado de adultos",
        "成人纸尿垫": "Almohadillas absorvente para pañales de adultos",


	"美妆护肤":"Belleza y cuidado corporal",


	"面部护肤":"Cuidado de la piel facial",
	"防晒": "Protector solar",
        "眼霜眼部精华": "Crema para ojos",
        "护肤": "Productos para el cuidado de la piel",
        "洁面": "Limpieza",
        "化妆水": "Tóner para la piel",
        "卸妆": "Desmaquillante",
        "润唇膏": "Bálsamo labial hidratante",
        "眼膜": "Máscara para ojos",
        "T区护理": "Enfermería en la zona t",
        "唇膜唇部精华": "Mascarilla labial esencia labial",

	"彩妆":"Maquillaje",
        "隔离霜/妆前乳": "Primer",
        "粉底液/膏": "Base líquida / crema",
        "唇彩唇蜜/唇釉": "Brillo labial",
        "气垫": "Esponja de maquillaje",
        "BB霜": "Crema bb",
        "蜜粉/散粉": "Polvo translúcido",
        "眉笔/眉粉": "Lápiz de cejas / polvo de cejas",
        "粉饼": "Base en polvo",
        "美甲": "Pintura de uñas",
        "眼影": "Sombra de ojos",
        "眼线笔/眼线液": "Delineador de ojos / delineador de ojos líquido",
        "遮瑕": "Corrector",
        "腮红/胭脂": "Rubor / carmín",
        "睫毛膏/睫毛液": "Rímel / Rímel",
        "CC霜": "Crema CC",
        "修容/高光/阴影": "Contorno / resaltado / sombra",
        "唇笔/唇线笔": "Lápiz labial / lápiz labial",

	"香水":"Perfume",
        "女士香水": "Perfume para mujer",
        "男士香水": "Perfume para hombres",

	"美妆工具":"Herramientas de belleza",
        "美甲工具": "Herramientas de uñas",
        "化妆棉": "Almohadilla de algodón cosmética",
        "化妆刷": "Brochas de maquillaje",
        "粉扑/洗脸扑": "Polvo espumoso / leche de lavado facial",
        "假睫毛": "Pestañas postizas",
        "修眉刀": "Recortador de cejas",
        "双眼皮贴": "Cinta para párpados dobles",
        "睫毛夹": "Rizador de pestañas",
        "美妆工具": "Herramientas de belleza",
        "吸油纸": "Papel absorbente de aceite",


	"家居厨具":"Hogar y cocina",

	"生活日用":"Vida diaria",
 	"香薰/蜡烛": "Aromaterapia / vela",
        "衣架": "Percha",
        "指甲刀": "Cortaúñas",
        "纸巾盒": "Caja de pañuelos",
        "雨伞雨具": "Paraguas y equipo de lluvia",
        "日杂小件": "Comestibles diarios",
        "梳子": "Peineta",
        "浴室置物架": "Estanterías de baño",
        "移动马桶": "Baño portátil",
        "保暖防护": "Protección térmica",
        "洗漱杯": "Taza de lavado",
        "搓澡巾": "Toallas de baño",
        "马桶刷": "Cepillo de inodoro",
        "净化除味": "Purificación y desodorización",
        "化妆镜": "Espejo de maquillaje",

	"水具酒具":"Utensilios de agua y vino",
        "保温杯": "Taza térmica",
        "玻璃杯": "Vaso cristal",
        "塑料杯": "Copa de plástico",
        "陶瓷/马克杯": "Copa Cerámica/ Taza",
        "保温壶": "Termo",
        "运动水壶": "Botella deportiva",
        "焖烧杯": "Taza de cocción lenta",
        "酒杯/酒具": "Copa de vino / utensilios de alcohol",

	"烹饪锅具":"Utensilios de cocina",
        "炒锅": "Sartén",
        "平底锅/煎锅": "Sartén",
        "汤锅": "Olla soperas",
        "蒸锅": "Vaporera",
        "奶锅": "Olla para leche",
        "高压锅": "Olla a presión",
        "火锅": "Olla caliente",

	"餐具":"Vajilla",
        "碗": "Tazón",
        "盘/碟": "Plato / plato",
        "筷子": "Palillos",
        "刀/叉/勺": "Cuchillo / tenedor / cuchara",
        "果盘/果篮": "Bandeja / cesta de frutas",
        "一次性用品": "Artículos desechables",

	"厨房配件":"Accesorios de cocina",
        "上水器": "Dispensador de agua",
        "真空机": "Envasador al vacío",
        "厨房储物器皿": "Trastero de cocina",
        "壁挂置物架": "Estantes de pared",
        "落地置物架": "Estantes de piso",
        "台面置物架": "Estante de encimera",
        "保鲜盒": "Caja de conservación",
        "饭盒/提锅": "Lonchera / olla",
        "调料器皿": "Envase para condimentos",
        "厨房秤": "Báscula de cocina",
        "烘培用具": "Utensilios para hornear",
        "厨房DIY/小工具": "Cocina Bricolaje / gadgets",

	"火机烟具":"Encendedores y accesorios para fumadores",
        "煤油打火机": "Encendedor de queroseno",
        "一次性打火机": "Encendedor desechable",
        "火机油": "Aceite para encendedor",
        "充电打火机": "Encendedor recargable",
        "烟嘴": "Soporte de cigarrillos",
        "烟斗": "Pipa",
        "雪茄用品": "Artículos para tabacos",
        "戒烟贴": "Parches de nicotina",
        "烟灰缸": "Cenicero",

	"茶具/咖啡具":"Juego de té y café",
        "茶杯": "Tazas",
        "茶壶": "Teteras",
        "茶具配件": "Accesorios de juego de té",
        "茶盘": "Bandeja de té",
        "咖啡杯": "Taza de café",
        "旅行茶具": "Juego de té de viaje",
        "咖啡具配件": "Accesorios de café",

	"刀剪菜板":"Cuchillos y placas de corte",
        "砧板": "Tabla de corte",
        "菜刀": "Cuchillo de cocina",
        "瓜果刀/刨": "Cuchillo para frutas/Cuchillo para pelar frutas",
        "剪刀": "Tijeras",
        "多功能到": "Cuchillo Multifuncional",

	"地毯底垫":"Almohadilla para alfombras",
        "浴室地垫": "Colchoneta del baño",
        "入户地垫": "Almohadilla de entrada",
        "商用地毯": "Alfombras comerciales",
        "床边毯": "Alfombra de dormitorio",
        "厨房地垫": "Alfombra de Sala",
        "客厅地毯": "Alfombra de Sala de estar",

	"收纳用品":"Suministros de almacenamiento",
        "收纳洞洞板": "Placa de clavos de almacenamiento",
        "首饰盒": "Caja de joyería",
        "夹缝收纳柜": "Taquilla agrietada",
        "化妆品收纳盒": "Caja de almacenamiento de cosméticos",
        "搬家纸箱": "Caja de mudanza",
        "收纳箱": "Caja de almacenamiento",
        "鞋盒": "Caja de zapatos",
        "搬家袋": "Bolsa de mudanza",
        "桌面收纳盒": "Caja organizadora escritorio",
        "内衣收纳盒": "Caja de almacenamiento de ropa interior",
        "真空收纳袋": "Bolsa de almacenaje al vacío",
        "旅行收纳袋": "Bolsa de almacenamiento de viajes",
        "化妆品收纳包": "Bolsa de almacenamiento de cosméticos",
        "手办收纳盒": "Caja de almacenamiento de figuras",
        "收纳柜": "Armarios de almacenamiento",

	"居家布艺":"Tejidos para el hogar",
        "毛巾浴巾": "Toallas y toallas de baño",
        "沙发垫": "Cojín para sofá",
        "门帘": "Cortina de la puerta",
        "一次性洗脸巾": "Pañuelo desechable",
        "飘窗垫": "Almohadilla para alféizar de ventana",
        "干发帽": "Gorro de secado",
        "窗纱": "Ventana de pantalla",
        "蒲团": "Zafu",
        "毛毯": "Mantas",
        "窗帘": "Cortinas",
        "抱枕靠垫": "Almohadas y cojines",
        "桌布": "Mantel",

	"床上用品":"Ropa de cama",
        "枕头": "Almohada",
        "床垫床褥": "Colchones",
        "床单床笠": "Sábanas",
        "蚊帐": "Mosquitero",
        "棉花被": "Edredón de algodón",
        "四件套": "Juego de cuatro piezas",
        "纤维被": "Edredón de fibra",
        "蚕丝被": "Edredón de seda",
        "羽绒服被": "Edredón",
        "电热毯": "Mantas eléctricas",
        "凉席": "Colchoneta fresca",
        "羊毛被": "Edredón de lana",
        "水暖毯": "Manta de agua caliente",
        "凉感被": "Edredón refrescante",


	"家电":"Electrodomésticos",


	"环境电器":"Electrodomésticos para el medio ambiente",
 	"电风扇": "Ventilador",
        "净水器": "Purificador de agua",
        "空气净化器": "Purificador de aire",
        "茶吧机": "Máquina de té",
        "除湿机": "Deshumidificador",
        "取暖器": "Calefactor",
        "电暖桌": "Mesa calefactora",
        "电油灯": "Lámpara de aceite",
        "软水机": "Ablandador de agua",
        "即热饮水机": "Dispensador de agua caliente",
        "加热净水器": "Calentador de agua filtrada",
        "全屋净水": "Purificación de agua en toda la casa",
        "加湿净化器": "Purificador humidificador",
        "无雾加湿器": "Humidificador sin vaho",
        "移动地暖": "Calefacción de suelo radiante móvil",

	"空调":"Aire acondicionado",
        "变频空调": "Aire acondicionado inverter",
        "新风空调": "Aire acondicionado de ventilación nueva",
        "新一级能效": "Nueva eficiencia energética de nivel uno",
        "空调柜机": "Aire acondicionado de consola",
        "空调挂机": "Aire acondicionado de pared",
        "中央空调": "Aire acondicionado central",

	"冰箱":"Refrigerador",
        "多门冰箱": "Refrigerador multipuerta",
        "对开门冰箱": "Refrigerador de puertas dobles",
        "十字对开门": "Puerta cruzada",
        "双门冰箱": "Refrigerador de doble puerta",
        "单门冰箱": "Refrigerador de una puerta",
        "三门冰箱": "Refrigerador de tres puertas",
        "冷柜/冰吧": "Congelador / bar de hielo",
        "红酒柜": "Enfriador de vino",

	"洗衣机":"Lavadora",
        "洗烘一体机": "Lavadora y secadora",
        "滚筒洗衣机": "Lavadora de tambor",
        "迷你洗衣机": "Mini lavadora",
        "烘干机": "Secadora",

	"电视":"Televisión",
        "K歌电视": "Karaoke TV",
        "艺术电视": "Televisión de arte",
        "会议电视": "Televisión de conferencias",
        "游戏电视": "Televisión de videojuegos",
        "85吋电视": "Televisión de 85 pulgadas",
        "98吋电视": "Televisión de 98 pulgadas",
        "75吋电视": "Televisión de 75 pulgadas",
        "miniled电视": "Televisor Mini LED",
        "电视挂架": "Soporte de televisión",
        "电视推车": "Carrito de televisión",
        "电视遥控器": "Control remoto de televisión",

	"厨房小电":"Electrodomésticos de cocina",
        "电饭煲": "Olla arrocera",
        "电水壶热水瓶": "Hervidor eléctrico",
        "电磁炉": "Cocina de inducción",
        "破壁机": "Batidora de alta potencia",
        "电压力锅": "Olla a presión eléctrica",
        "养生壶": "Tetera de salud",
        "电饼档": "Crepera eléctrica",
        "微波炉": "Horno de microondas",
        "空气炸锅": "Freidora de aire",
        "咖啡机": "Cafetera",
        "电烤箱": "Horno eléctrico",
        "三明治早餐机": "Sandwichera",
        "果蔬净化器": "Purificador de frutas y verduras",
        "炒菜机器人": "Robot de cocina",
        "电动榨汁杯": "Licuadora eléctrica portátil",
        "电煮锅": "Olla arrocera",
        "刀筷消毒机": "Máquina desinfectadora de cuchillos y palillos",
        "电热火锅": "Olla caliente eléctrica",
        "电蒸锅": "Vaporera eléctrica",
        "厨师机": "Robot de cocina",
        "绞肉机": "Picadora de carne",

	"厨卫大电":"Electrodomésticos de cocina y baño",
        "油烟机": "Campana extractora",
        "灶具": "Fogón",
        "电热水具": "Calentador de agua eléctrico",
        "燃气热水器": "Calentador de agua a gas",
        "消毒柜": "Gabinete de desinfección",
        "洗碗机": "Lavavajillas",
        "壁挂炉": "Caldera de pared",
        "空气能热水器": "Calentador de agua por energía de aire",
        "太阳能热水器": "Agua caliente solar",
        "嵌入式厨电": "Electrodomésticos empotrados",
        "电热水龙头": "Grifo de agua caliente eléctrico",
        "集成水槽": "Fregadero integrado",
        "集成灶": "Cocina integrada",

	"个护健康":"Cuidado personal y salud",
        "干衣机": "Secadora",
        "剃须刀": "Afeitadora",
        "按摩器": "Masajeador",
        "电吹风": "Secador de pelo",
        "电动牙叔": "Cepillo detal eléctrico",
        "理发器": "Cortapelos",
        "美容器": "Aparato de belleza",
        "卷/直发器": "Rizador/Plancha de pelo",
        "冲牙器": "Irrigador dental",
        "按摩椅": "Silla de masaje",
        "足浴盆": "Bañera de pies",
        "剃/脱毛器": "Afeitadora / depiladora",
        "潮流护理电器": "Aparato de cuidado personal",
        "洗手机": "Lavamanos",
        "手持挂烫机": "Plancha de vapor de mano",

	"家电配件":"Accesorios de electrodomésticos",
        "燃气灶支架": "Soporte para cocina de gas",
        "个护健康配件": "Accesorios de cuidado personal y salud",
        "电视支架": "Soporte de televisión",
        "冰箱配件": "Accesorios de refrigeradores",
        "电视艺术支架": "Soporte de televisión artístico",
        "吸尘器支架": "Soporte para aspiradora",
        "热水器镁棒": "Barra de magnesio para calentador de agua",
        "聚水罩": "Cúpula de recolección de agua",
        "冰箱门封条": "Sello de la puerta del refrigerador",
        "生活电器配件": "Accesorios de electrodomésticos",
        "洗衣机配件": "Accesorios para lavadoras",
        "空调挡风板": "Deflector aire acondicionado",
        "电视配件": "Accesorios para televisión",
        "空调遥控器": "Control remoto del aire acondicionado",
        "空调配件": "Accesorios para aire acondicionado",
        "冰箱底座架": "Base para refrigerador",
        "洗衣机底座": "Base de la lavadora",
        "空调支架": "Soporte de aire acondicionado",
        "厨房小电配件": "Accesorios de electrodomésticos de cocina",
        "厨卫大电配件": "Accesorios de electrodomésticos de cocina y baño",
        "洗衣机排水管": "Tubo de la lavadora",

	"清洁电器":"Electrodomésticos de limpieza",
        "扫地机器人": "Robot aspirador",
        "蒸汽电动拖把": "Mopas de limpieza a vapor",
        "除螨仪": "Aspiradora de ácaros",
        "擦窗机器人": "Robot limpiacristales",
        "洗地机": "Fregadoras de suelos",

	"视听影音":"Audiovisual",
        "麦克风": "Micrófono",
        "KTV音响": "Sonido KTV",
        "家庭影响": "Sistema de sonido para casa",
        "功放": "Amplifier",
        "迷你音响": "Mini altavoz",
        "电视音响": "Altavoces para televisión",


	"家居家装":"Decoración del hogar"	,


	"五金工具":"Herramientas de hardware",
	"家用五金": "Hardware doméstico",
        "电钻": "Taladradora",
        "螺丝刀": "Destornillador",
        "增压泵": "Bomba de refuerzo",
        "水平仪": "Nivel",
        "工具套装": "Kits de herramientas",
        "电锯": "Sierra eléctrica",
        "角磨机": "Molino de ángulo",
        "测距仪": "Telémetro",
        "五金工具箱": "Caja de herramientas de hardware",
        "水平尺": "Topografía",
        "家用电梯": "Ascensor doméstico",
        "购物车": "Carrito de compras",
        "机械锁": "Cerradura mecánica",
        "智能锁": "Cerradura inteligente",

	"电工电料":"Materiales eléctricos",
        "开关插座": "Interruptores y tomacorrientes",
        "智能家居": "Hogar inteligente",
        "电料配件": "Accesorios eléctricos",
        "智能开关": "Conmutador inteligente",
        "电线/电缆": "Cables / cables",
        "配电箱/断路器": "Caja de distribución / Disyuntor",
        "电气控制": "Control eléctrico",
        "智能窗帘": "Cortinas inteligentes",

	"书房家具":"Muebles de estudio",
        "电脑桌": "Escritorio de computadora",
        "电脑椅": "Silla de computadora",
        "书架": "Estantería",
        "书桌": "Escritorio",
        "升降桌": "Mesa elevable",
        "红木书桌": "Escritorio de madera palisandro",

	"餐厅家具":"Muebles de restaurante",
        "岩板餐桌": "Mesa de piedra",
        "实木餐桌": "Mesa de madera maciza",
        "岛台餐桌": "Mesa de isla",
        "折叠餐桌": "Mesa plegable",
        "餐车": "Coche restaurante",
        "餐边柜": "Aparador",
        "餐椅": "Silla de comedor",
        "红木餐桌": "Mesa de madera palisandro",
        "红木餐边桌": "Mesa auxiliar de madera palisandro",

	"阳台储物":"Almacenamiento en el balcón",
        "飘窗桌": "Mesa de ventana convexa",
        "层架/置物架": "Estanterías / estantes",
        "智能晾衣架": "Tendederos inteligentes",
        "阳台柜": "Gabinete del balcón",
        "衣帽架": "Perchero",
        "落地晾衣架": "Estante de secado de suelo",
        "隐藏晾衣架": "Tendedero oculto",
        "鞋架": "Estante de zapatos",
        "储物/收纳": "Almacenamiento",
        "手摇晾衣架": "Tendedero manual",
        "墙面搁架": "Estanterías de pared",
        "泳池躺椅": "Tumbona en la piscina",

	"厨房卫浴":"Cocina y baño",
        "厨房配件": "Accesorios de cocina",
        "淋浴花洒": "Ducha",
        "龙头": "Grifo de agua",
        "厨卫挂件": "Accesorios de cocina y baño",
        "水槽": "Fregadero",
        "马桶": "Inodoro",
        "陶瓷件组套": "Juego de piezas de cerámica",
        "浴室柜": "Gabinete del baño",
        "浴霸": "Calentador de baño",
        "净水软水": "Agua purificada y blanda",
        "淋浴房": "Ducha",
        "智能马桶盖": "Tapa de inodoro inteligente",
        "浴缸": "Bañera",
        "沐浴桶": "Bañera",
        "垃圾处理器": "Triturador de basura",

	"客厅家具":"Muebles de Sala",
        "边桌/茶几": "Mesa lateral / Mesa de café",
        "布艺沙发": "Sofá de tela",
        "皮艺沙发": "Sofá de cuero",
        "鞋柜": "Zapatero",
        "沙发床": "Sofá cama",
        "电视柜": "Gabinete de televisión",
        "穿衣镜": "Espejo de tocador",
        "实木沙发": "Sofá de madera maciza",
        "屏风": "Biombo",
        "懒人沙发": "Sofá perezoso",
        "功能沙发": "Sofá funcional",
        "酒柜": "Gabinete de vino",
        "红木沙发": "Sofá de madera de palisandro",
        "壁炉": "Chimenea",
        "红木电视柜": "Gabinete de televisión de palisandro",

	"庭院户外":"Patio al aire libre",
        "折叠穿": "chaqueta de mantaña",
        "遮阳伞": "Sombrilla",
        "充气家具": "Muebles inflables",
        "遮阳棚": "Toldo",
        "家用梯": "Escalera doméstica",
        "户外桌椅": "Mesas y sillas al aire libre",
        "木屋凉亭": "Pabellón de madera",
        "秋千": "Columpios",
        "岗亭岗台": "Garita de centinela",
        "户外沙发": "Sofá al aire libre",
        "吊篮": "Cesta colgante",

	"卧室家具":"Muebles de dormitorio",
        "实木床": "Cama de madera maciza",
        "皮艺床": "Cama de cuero",
        "衣柜": "Armario",
        "实木衣柜": "Armario de madera maciza",
        "床头柜": "Mesita de noche",
        "床垫": "Colchones",
        "乳胶床垫": "Colchón de muelles",
        "弹簧床垫": "Colchón de primavera",
        "简易衣柜": "Armario simple",
        "斗柜": "Cómoda",
        "布艺床": "Cama de tela",
        "床尾凳": "Banco de cama",
        "红木床": "Cama de madera de palisandro",
        "红木衣柜": "Armario de madera de palisandro",
        "梳妆台/凳": "Tocador / taburete",

	"儿童家具":"Muebles para niños",
        "高低床": "Litera",
        "绘本架": "Estante de libros ilustrados",
        "儿童床": "Cama infantil",
        "边床": "Cama lateral",
        "儿童桌椅套装": "Juego de mesas y sillas para niños",
        "儿童椅": "Silla infantil",
        "儿童床垫": "Colchones para niños",
        "儿童桌": "Mesa para niños",
        "儿童书桌": "Escritorio infantil",
        "儿童衣柜": "Armario infantil",
        "儿童沙发": "Sofá infantil",
        "儿童收纳储物": "Trastero infantil",
        "儿童凳": "Taburete infantil",
        "儿童玩具桌": "Mesa de juguetes para niños",

	"商业办公":"Oficina Comercial",
        "适老家具": "Muebles para ancianos",
        "餐饮家具": "Muebles de restauración",
        "会议桌": "Mesa de conferencias",
        "货架": "Estanterías",
        "办公椅": "Silla de oficina",
        "文件柜": "Archivador",

	"灯饰照明":"Iluminación",
        "吸顶灯": "Lámpara de techo",
        "台灯": "Lámpara de Mesa",
        "吊灯": "Lámpara de araña",
        "壁灯": "Lámparas de pared",
        "镜前灯": "Espejo de luz",
        "落地灯": "Lámpara de piel",
        "氛围照明": "Iluminación emocional",
        "杀菌灯": "Lámparas bactericidas",


	"母婴":"Madres y bebés",


	"奶粉":"Leche en polvo",
	"特殊配方奶粉": "Fórmula especial",
        "有机奶粉": "Leche en polvo orgánica",
        "1段": "Etapa 1",
        "2段": "Etapa 2",
        "3段": "Etapa 3",
        "4段": "Etapa 4",
        "羊奶粉": "Leche cabra en polvo",
        "孕妈奶粉": "Leche en polvo para mujeres embarazadas",

	"尿布湿巾":"Toallitas y pañales",
        "拉拉裤": "pañales para bebés",
        "成人尿裤": "Pañales para adultos",
        "婴儿湿巾": "Toallitas para bebés",

	"喂养工具":"Herramientas de alimentación",
        "奶瓶奶嘴": "Biberón y chupete",
        "水壶/水杯": "Jarra/ Taza",
        "儿童餐具": "Vajilla infantil",
        "暖奶调奶": "Calentador de biberones",
        "吸奶器": "Aspirador de leche",
        "牙胶安抚": "Gel para encías",
        "辅食料理机": "Licuadora de alimentos para bebés",
        "食物存储": "Armario de alimentos",
        "消毒锅/柜": "Esterilizador",

	"洗护用品":"Artículos de aseo",
        "宝宝护肤": "Cuidado de la piel de los bebés",
        "驱蚊防晒": "Repelente de mosquitos y protector solar",
        "洗发沐浴": "Champú y gel de baño",
        "婴儿洗鼻器": "Aspirador nasal para bebés",
        "日常护理": "Cuidado diaria",
        "口腔清洁": "Higiene bucal",
        "洗澡用具": "Productos de baño",
        "棉柔巾": "Toallas de algodón",
        "洗衣液/皂": "Detergente líquido / Jabón",
        "坐便器": "Inodoro",
        "奶瓶清洗": "Lacado de biberones",
        "婴儿理发器": "Cortapelos para bebés",

	"营养辅食":"Alimentos complementarios nutritivos",
        "宝宝零食": "Bocadillos para bebés",
        "有机辅食": "Alimentos complementarios orgánicos",
        "米粉/菜粉": "Fideos de arroz / fideos vegetales",
        "面条/粥": "Fideos / gachas",
        "果泥/果汁": "Puré / jugo",
        "清火/开胃": "Refrescar / apetito",
        "DHA": "DHA",
        "益生菌/初乳": "Probióticos / calostro",
        "钙铁锌/维生素": "Calcio, hierro, zinc / vitamina",


	"玩具":"Juguetes",


	"潮流玩具/毛绒":"Juguetes de moda / peluche",
        "卡通周边": "Merchandising de dibujos animados",
        "毛绒玩具": "Juguetes de peluche",
        "盲盒": "Caja sorpresa",
        "网游周边": "Merchandising de juegos en línea",
        "变形模型": "Jeguetes deformable",
        "电影周边": "Merchandising de películas",
        "人偶/BJD/兵人": "Muñeca / BJD / Figurita soldado",
        "陀螺": "Peonza",
        "靠垫抱枕": "Cojines",
        "悠悠球/溜溜球": "Yo yo",
        "扭蛋": "Huevo sorpresa",

	"创意减压":"Descompresión creativa",
        "减压整蛊": "Juguetes de broma",
        "魔术道具": "Accesorios mágicos",
        "怀旧玩具": "Juguetes nostálgicos",
        "转转笔": "Bolígrafo giratorio",
        "指尖陀螺": "Fidget Spinner",
        "解锁玩具": "Desbloquear juguetes",

	"早教益智":"Problemas de la educación temprana",
        "早教机/故事机": "Máquina de educación infantil / Máquina de contar historias",
        "积木": "Bloques de construcción",
        "过家家玩具": "Juguetes para jugar a las casitas",
        "拼图": "Rompecabezas",
        "绘画工具": "Herramientas de pintura",
        "爬行垫/毯": "Alfombras/ Mantas de gateo",
        "魔方": "Cubo de Rubik",
        "桌面玩具": "Juguetes de Mesa",
        "STEAM玩具": "Juguetes de vapor",
        "仿真动物玩具": "Juguetes para animales simulados",
        "摇铃/床铃": "Sonajero/ Campana de cama",
        "磁力片": "Fichas magnética",
        "健身架": "Marco de fitness",
        "敲打玩具": "Juguetes de percusión",
        "点读笔": "Bolígrafo lector",

	"遥控/玩具":"Juguetes de control remoto",
        "车模": "Modelo de automóvil",
        "软弹枪": "Pistola de bala blanda",
        "遥控车": "Automóvil teledirigido",
        "遥控飞机": "Avión teledirigido",
        "轨道车": "Vagón ferroviario",
        "飞机模型": "Modelo de avión",
        "遥控船": "Barco teledirigido",
        "机器人": "Robot",

	"运动划水":"Remo y deporte",
        "洗澡玩具": "Juguetes de baño",
        "泡泡枪": "Pistola de burbujas",
        "玩沙玩具": "Juguetes de arena",
        "电动玩具枪": "Pistola de juguete eléctrica",
        "摇马": "Caballo oscilante",
        "儿童滑梯": "Tobogán infantil",
        "炫舞毯": "Alfombra de baile",

	"特色类目":"Categoría destacada",
        "手办": "Figura en miniatura",


	"文具":"Papelería",


	"笔类书写工具":"Herramienta de escritura",
 	"记号笔": "Rotulador",
        "中性笔": "Bolígrafo de gel",
        "钢笔": "Pluma",
        "铅笔": "Lápiz",
        "笔芯/替芯": "Mina de bolígrafo / Recambio de bolígrafo",
        "墨囊/墨水": "Bolsa de tinta / tinta",
        "荧光笔": "Subrayador",
        "圆珠笔": "Bolígrafo",
        "白板笔": "Marcador para pizarrón",
        "宝珠笔": "Bolígrafo de bola",
        "针管笔": "Pluma de aguja",
        "油漆笔": "Rotulador de pintura",
        "秀丽笔": "Pluma de caligrafía",

	"本册/印刷品":"Libretas / Impresiones",
        "笔记本记事本": "Cuaderno",
        "作业本": "Cuaderno de ejeicicios",
        "便利贴": "Nota adhesiva",
        "书签": "Marcapáginas",
        "标签贴纸": "Etiqueta adhesiva",
        "手账": "Agenta personal",
        "文稿纸草稿纸": "Papel manuscrito",
        "相册": "Álbum de fotos",
        "信封/红包": "Sobre / sobre rojo",
        "证书签到册": "Libro de registro",
        "活页替芯": "Recambios de hojas sueltas",
        "贺卡/明信片": "Tarjetas de felicitación / postales",
        "日程本计划本": "Agenta",
        "日历/台历": "Calendario / calendario de mesa",
        "日记本": "Diario",
        "信纸": "Papel de carta",
        "冰箱贴防撞贴": "Pegatinas anticolisión para el refrigerador",
        "宣传单/海报": "Folletos / carteles",
        "同学录纪念册": "Libro de recuerdos",
        "拍纸本": "Cuaderno en papel",
        "广告纸爆炸贴": "Pegatinas explosivas/ papel publicitario",
        "分页纸索引纸": "Papel de separación/ papel de índice",
        "奖状": "Certificado de excelencia",
        "教学用本": "Materiales didácticos",
        "立体便签拼图": "Rompecabezas de pegatinas 3D",
        "电话本通讯录": "Agenda de contactos",

	"学习用品":"Suministros escolares",
        "练字帖": "Ejercicios de caligrafía",
        "文具盒/笔袋": "Caja de lápices / bolsa de lápices",
        "橡皮擦": "Goma de borrar",
        "修正带": "Cinta de corrección",
        "卷笔刀卷比器": "Sacapuntas",
        "贴纸": "Pegatinas",
        "补习袋": "Paquete Tutorial",
        "书皮/书套": "Sobrecubierta",
        "坐姿修正器": "Corrector postura espalda",
        "阅读器": "Lector",
        "姓名贴": "Etiqueta nombre",
        "握笔器": "Agrrador de lápiz",
        "自律打卡器": "Reloj autodisciplinado",
        "学生计时器": "Temporizador de estudiantes",
        "学生书桌垫": "Alfombrilla de escritorio para estudiantes",
        "儿童卡通印章": "Sello de dibujos animados para niños",
        "修正液": "Corrector líquido",
        "笔套/笔帽": "Capuchón",
        "姓名印章": "Sello de nombre",
        "桌面吸尘器": "Aspiradora de escritorio",
        "答题卡/纸": "Hoja de respuestas",
        "挂书袋": "Bolsa de libros",
        "红领巾": "Pañoleta roja",
        "词卡/图卡": "Tarjeta de texto / tarjeta de imagen",
        "放大镜": "Lupa",
        "拼音机口算机": "Máquina de pinyin/ Calculadora mental",
        "学习护指绷带": "Vendaje para el dedo del estudiante",
        "少先队徽": "Insignia de la Liga de Jóvenes Pioneros",
        "压花器": "Máquina de estampado",
        "修正贴": "Pegatinas de correcciones",
        "少先队标志": "Símbolo de la Liga de Jóvenes Pioneros",

	"财会用品":"Suministros contables",
        "凭证/单据": "Comprobantes / recibos",
        "印章/印油": "Sello / tinta de sello",
        "账本/账册": "Libro de cuentas",
        "橡皮筋": "Goma elástica",
        "号码机": "Máquina de numeración",
        "印章盒/箱": "Caja / Caja de sellos",
        "复写纸": "Papel carbón",
        "支票夹": "Cartera de cheques",
        "支票打印机": "Comprobar impresoras",
        "湿手器": "Humedecedor de manos",
        "财会软件": "Software contable",
        "手提金库": "Caja fuerte portátil",

	"其他文具用品":"Otros artículos de papelería",
        "旗帜": "Bandera",
        "桌牌": "Tarjeta de Mesa",
        "usb风扇": "Ventilador USB",
        "徽章": "Insignia",
        "奖杯奖牌": "Trofeos y medallas",
        "温湿度计": "Termómetros y higrómetros",
        "会议指示牌": "Cartel punto de reunión",
        "许愿瓶": "Botella de deseo",

	"美术用品":"Suministros artísticos",
        "美术颜料": "Pintura artística",
        "马克笔": "Rotulador",
        "画纸/画本": "Papel de dibujo/ Cuaderno de dibujo",
        "素描铅笔碳棒": "Lápiz de dibujo/ Barra de carbono",
        "水彩笔": "Rotulador de acuarela",
        "颜料画笔": "Pincel",
        "画架/画板": "Caballete / tablero de dibujo",
        "彩色铅笔": "Lápices de colores",
        "油画棒": "Palo de pintura al óleo",
        "调色盘/纸/盒": "Paletas de colores / papel / cajas",
        "画布/画框": "Lienzo / Marco de pintura",
        "画袋/画包": "Bolsa de dibujo",
        "勾线笔": "Bolígrafo de trazos",
        "蜡笔": "Crayón",
        "揉擦笔揉擦棉": "Bolígrafo de borrado",
        "石膏雕塑": "Escultura de yeso",
        "色卡": "Paleta de colores",
        "拷贝台临摹台": "Mesa para calcar",
        "油画刀/刮刀": "Cuchillo de paleta / espátula",
        "粉彩棒色粉笔": "Tiza de colores",
        "刮画纸": "Papel de rascar",
        "写生车/画车": "Dibujo de automóviles / pintura de automóviles",
        "风干/速干器": "Secador de aire / secador rápido",
        "绘画油壶": "Olla de aceite de pintura",
        "喷绘/取色笔": "Aerógrafo / Sacador de colores",
        "画杖": "Palo de apoyo",

	"裁剪工具":"Herramientas de corte",
        "美工刀": "Cúter",
        "剪刀": "Tijeras",
        "裁纸刀": "Cortapapeles",
        "开箱器": "Abre-cajas",
        "启信刀拆信刀": "Abrecartas",
        "宣纸/制品": "Papel de arroz / producto",

	"文房四宝":"Suministros para cuarto de estudio",
        "毛笔": "Pincel de tinta",
        "文房配件": "Accesorios escritorio",
        "墨汁/墨锭": "Tinta china aguada/Tinta china en barra",
        "文房套装": "Juego de papelería",
        "毛笔字帖": "Cuaderno de caligrafía con pincel",
        "砚台": "Tintero chino",
        "书画毛毡": "Fieltro para caligrafía y pintura",
        "镇尺/镇纸": "Pesas",
        "水写布": "Tela de escritura",
        "石章": "Sello de piedra",
        "文房印泥": "Pasta de tinta roja",
        "篆刻工具": "Herramientas para sellos y estampado",

	"收纳用品":"Suministros de almacenamiento",
        "卡套/证件套": "Estuche de tarjetas / Estuche de ID",
        "资料册": "Folleto informativo",
        "夹板": "Tablilla",
        "档案/资料盒": "Carpeta de archivos/ Carpeta de documentos",
        "文件袋": "Bolsa de documentos",
        "文件筐": "Cesta de documentos",
        "笔筒": "Cubilete para lápices",
        "文件套": "Carpeta de documentos",
        "抽杆/报告夹": "Folder de informes",
        "书立": "Soporte de libros",
        "档案袋": "Bolsa de proyectos",
        "科目袋": "Paquete temático",
        "风琴包/袋": "Bolsa de acordeón",
        "事务包": "Maletín",
        "钥匙管理": "Llavero",
        "意见/抽奖箱": "Caja de sugerencias/ Caja de sorteos",
        "书报/展示架": "Expositor de libros y periódicos",
        "证件框": "Marco de documentos",
        "快劳夹": "Pinzas rápidas",
        "孔装夹": "Pinzas de agujero",
        "办公纸箱": "Cartón de oficina",
        "桌面文件柜": "Gabinete de escritorio",
        "活页袋": "Bolsa de hojas sueltas",
        "名片盒": "Caja de tarjetas de visita",
        "名片册": "Libro de tarjetas de visita",
        "边角夹": "Pinzas de esquina",
        "报纸夹": "Sujeta periódicos",
        "吊挂夹": "Pinzas para colgar",

	"胶粘用品":"Productos de pegamentos",
        "透明胶带": "Cinta transparente",
        "胶水": "Pegamento",
        "双面胶带": "Cinta adhesiva de doble cara",
        "固体胶": "Barra de pegamento",
        "纳米胶带": "Cinta nanométrica",
        "美纹纸胶带": "Cinta de enmascarar",
        "布基胶带": "Cinta americana",
        "点点胶": "Pegamento",
        "警示胶带": "Cinturón de advertencia",
        "绝缘胶带": "Cinta aislante",
        "铝箔胶带": "Cinta de aluminio",
        "牛皮纸": "Papel de estraza",
        "封箱器": "Sellador de cajas",
        "学生白胶": "Pegamento blanco para estudiantes",
        "隐形胶带": "Cinta invisible",
        "胶带座": "Soporte de cinta",
        "浆糊": "Engrudo",

	"绘图计算用品":"Suministros gráficos e informáticos",
        "计算器": "Calculadora",
        "直尺": "Regla",
        "测绘套装": "Kit de topografía",
        "圆规": "Compás",
        "皮卷尺/软尺": "Cinta métrica de cuero",
        "钢卷尺": "Cinta métrica de acero",
        "三角尺": "Regla triangular",
        "算盘": "ábaco",
        "手抄报模板": "Plantilla de periódico mural",
        "绘图模板": "Plantilla de dibujo",
        "比例尺": "Escala",
        "量角器": "Transportador",
        "丁字尺": "Regla T",

	"装订用品":"Productos de encuadernación",
        "票夹/长尾夹": "Portafolios /Pinzas de cola larga",
        "磁扣/磁钉": "Hebillas magnéticas / clavos magnéticos",
        "订书机": "Grapadora",
        "打孔机": "Perforadora",
        "回形针": "Clip",
        "推夹器": "Clip de empuje",
        "别针": "Alfiler de gancho",
        "大头针": "Alfiler",
        "起钉器": "Quitagrapas",
        "图钉": "Chincheta",
        "三针一钉": "Paquete de clavos",

	"教学实验用品":"Suministros didácticos-prácticos",
        "数学教具": "Equipos y materiales didácticos",
        "地球仪": "Globo terráqueo",
        "教鞭/手指棒": "Puntero",
        "粉笔": "Tiza",
        "黑板": "Pizarra",
        "板擦": "Borrador",
        "教学模型": "Modelo de enseñanza",
        "教学刻章": "Enseñanza de la talla de sellos",
        "教学标本": "Especímenes didácticos",
        "显微镜": "Microscopio",

	"手工/DIY":"Obra manual/ DIY",
        "彩泥/粘土": "Arcilla de colores",
        "折纸/DIY": "Origami / DIY",
        "咕卡套装": "Conjuto de Guka",
        "火漆印章": "Sello de lacre",
        "3D打印笔": "Bolígrafo de Impresión 3D",
        "剪纸/DIY": "Papel recortado/ DIY",
        "奖励刮刮卡": "Tarjeta raspa y gana",
        "礼品纸": "Papel de regalo",
        "纸雕": "Escultura de papel",
        "衍纸": "Filigrana de papel",


	"电脑办公":"Oficina informática",


	"办公用纸":"Papel de oficina",
 	"工程纸": "Papel de ingeniería",
        "其他标签纸": "Otros papeles de etiqueta",
        "彩激纸": "Papel láser de color",
        "其他纸类": "Otros papeles",
        "传真纸": "Papel de fax",
        "卡纸": "Papel atascado",
        "铜版纸": "Papel recubierto",
        "条码标签纸": "Papel de etiqueta de código de barras",
        "相片纸": "Papel fotográfico",
        "复印纸": "Papel de fotocopiadora",
        "收银纸": "Papel de caja registradora",

	"外设产品":"Productos periféricos",
        "手机U盘": "USB móvil",
        "线缆": "Cable",
        "键盘配件": "Accesorios de teclado",
        "平板电脑配件": "Accesorios para tablets",
        "机械键盘": "Teclado mecánico",
        "插座": "Enchufe",
        "笔记本配件": "Accesorios para Laptops",
        "鼠标": "Ratón",
        "鼠标垫": "Almohadilla para el ratón",
        "U盘": "Memoria flash",
        "扩展坞": "Muelle de expansión",
        "投屏器": "Proyector de pantalla",
        "移动硬盘": "Disco duro externo",
        "移动固态硬盘": "SSD portátil",
        "硬盘盒": "Caja de disco duro",
        "UPS电源": "Fuente de alimentación ups",
        "摄像头": "Cámara",
        "手写板": "Tablero de escritura a mano",
        "网络仪表": "Instrumentos de red",
        "机柜": "Gabinete",
        "电脑工具": "Herramientas informáticas",
        "电脑清洁": "Limpieza de computadoras",

	"电脑整机":"Computadora",
        "闺蜜机": "Máquina de novias",
        "轻薄本": "Laptop ligero",
        "二合一平板": "Tableta en dos",
        "游戏本": "Laptop para juegos",
        "商用台式机": "Computadora de escritorio comercial",
        "游戏台式机": "Computadora de escritorio para juegos",
        "台式机": "Computadora de escritorio",
        "一体机": "Todo en uno",
        "服务器/工作站": "Servidores / estaciones de trabajo",

	"耗材":"Consumibles",
        "硒鼓": "Caja de tóner",
        "墨盒": "Cartucho de tinta",
        "色带": "Cinta de colores",
        "纸类": "Papeles",
        "刻录光盘": "Grabar CD - ROM",
        "胶带": "Cinta adhesiva",

	"电脑组件":"Componentes de computadora",
        "显示器": "Monitor",
        "显示器配件": "Accesorios de monitor",
        "SSD固态硬盘": "Disco de estado sólido SSD",
        "散热器": "Radiadores",
        "内存": "Memoria",
        "显卡": "Tarjeta gráfica",
        "机箱": "Caja de computadora",
        "组装电脑": "Computadora ensamblada",
        "电源": "Fuente de alimentación",
        "主板": "Placa base",
        "硬盘": "Disco duro",
        "装机配件": "Accesorios de montaje",
        "CPU": "Unidad central de procesamiento",
        "刻录机/光驱": "Grabadora / Unidad de disco óptico",
        "声卡/扩展卡": "Tarjeta de sonido / tarjeta de expansión",

	"办公设备":"Equipo de oficina",
        "打印机": "Impresoras",
        "投影机": "Proyector",
        "投影配件": "Accesorios de proyector",
        "家用打印机": "Impresoras domésticas",
        "传真设备": "Equipo de fax",
        "验钞/点钞机": "Detector / contador monetario",
        "扫描仪": "Escáneres",
        "条码扫描/采集器": "Escáneres / colectores de código de barras",
        "复合机": "Máquina compuesta",
        "碎纸机": "Trituradora",
        "考勤门禁": "Control de asistencia y acceso",
        "收银机": "Caja registradora",
        "激光笔": "Indicador láser",
        "保险柜/箱": "Caja fuerte / caja fuerte",
        "打码机": "Máquina de Codificación",
        "安防监控": "Monitoreo de Seguridad",
        "会议音频视频": "Sistema de conferencia de audio y video",
        "写字板/支架": "Tablero de escritura / soporte",
        "办公大屏": "Pantalla grande de la Oficina",
        "会议平板": "Tableta de conferencias",
        "广告机": "Máquina de publicidad",

	"网络产品":"Productos de red",
        "路由器": "Router",
        "网线": "Cables de red",
        "网络配件": "Accesorios de red",
        "交换机": "Conmutador",
        "5G/4G上网": "Acceso a Internet 5g / 4G",
        "网卡": "Tarjeta de red",
        "网络盒子": "Caja de red",
        "网络存储": "Almacenamiento en red",

	"游戏设备":"Dispositivos de juego",
        "手柄/方向盘": "Mango / volante",
        "游戏软件": "Software de juego",
        "游戏周边": "Merchandising de videojuegos",
        "游戏机": "Videoconsola",
        "游戏耳机": "Auriculares para juego",


	"数码":"Productos digitales",


	"数码趋势":"Tendencias digitales",
 	"AI学习机": "Máquina de aprendizaje de IA",
        "开放式耳机": "Auriculares abiertos",
        "智能健康手表": "Reloj inteligente de salud",
        "Vlog相机": "Cámara de vlogs",
        "AR眼镜": "Gafas de AR",
        "AI手表": "Relojes de IA",
        "AI眼镜": "Gafas de IA",
        "AI相机": "Cámara de IA",
        "AI耳机": "Auriculares de IA",
        "AI音箱": "Altavoces de IA",
        "AI门锁": "Cerradura de puerta de IA",
        "AI摄像头": "Cámara de IA",

	"影音娱乐":"Entretenimiento de audio y video",
        "直播设备": "Equipo de transmisión en vivo",
        "居家艺术音箱": "Altavoz de arte para el hogar",
        "HIFI音箱": "Altavoces HiFi",
        "降噪耳机": "Auriculares cancelación ruido",
        "耳夹式耳机": "Auriculares de clip",
        "运动耳机": "Auriculares deportivos",
        "眼镜耳机": "Auriculares con gafas",
        "颈挂式耳机": "Auriculares de cuello",
        "头戴式耳机": "Auriculares de diadema",
        "骨传导耳机": "Auriculares de conducción ósea",
        "蓝牙耳机": "Auriculares de Bluetooth",
        "乐器音箱": "Altavoces de instrumentos musicales",
        "舞台音箱": "Altavoz de escenario",
        "拉杆/K歌": "Altavoz con ruedas",
        "智能音箱": "Altavoz inteligente",
        "音箱/音响": "Altavoces / Equipos de sonidos",
        "有线耳机": "Auriculares con cable",
        "麦克风": "Micrófono",
        "游戏耳机": "Auriculares para juegos",
        "MP3/MP4": "Mp3 / mp4",
        "专业音频": "Audio profesional",
        "音频线": "Cable de audio",
        "收音机": "Radio",

	"数码配件":"Accesorios digitales",
        "usb风扇": "Ventilador USB",
        "智能手环配件": "Accesorios de pulsera inteligentes",
        "手持稳定器": "Estabilizador de mano",
        "镜头附件": "Accesorios para lentes",
        "智能手表配件": "Accesorios de reloj inteligentes",
        "耳机配件": "Accesorios para auriculares",
        "电池/充电器": "Batería / cargador",
        "储存卡": "Tarjeta de memoria",
        "机身附件": "Accesorios de cámaras",
        "相机包": "Paquete de cámara",
        "三脚架/云台": "Trípode / plataforma giratoria",
        "滤镜": "Filtro",
        "运动相机配件": "Accesorios de cámaras deportivas",
        "读卡器": "Lector de tarjetas",
        "相机清洁/贴膜": "Limpieza de la Cámara / protector de pantalla",
        "闪光灯/手柄": "Flash / Mango",

	"智能设备":"Dispositivos inteligentes",
        "电话手表": "Reloj inteligente con SIM",
        "XR设备": "Dispositivos xR",
        "智能戒指": "Anillo inteligente",
        "监控摄像": "Cámara de vigilancia",
        "智能家具": "Muebles inteligentes",
        "智能儿童手表": "Relojes inteligentes infantiles",
        "无人机": "Drones",
        "健康监测": "Vigilancia Sanitaria",
        "智能机器人": "Robot inteligente",
        "智能配饰": "Accesorios inteligentes",
        "智能出行": "Movilidad inteligente",
        "运动跟踪器": "Rastreador de movimiento",

	"摄影摄像":"Fotografía y vídeo",
        "数码相框": "Marco digital",
        "影棚器材": "Equipo de estudio",
        "微单相机": "Cámara sin espejo",
        "拍立得": "Polaroid",
        "数码相机": "Cámara digital",
        "单反相机": "Cámara réflex",
        "冲印服务": "Servicios de impresión",
        "摄像机": "Cámara de vídeo",
        "户外器材": "Equipos al aire libre",

	"电子教育":"Educación electrónica",
        "学习机": "Máquina de aprendizaje",
        "电纸书": "Libros electrónicos",
        "电子词典": "Diccionario electrónico",
        "录音笔": "Grabadora de voz",
        "点读机/笔": "Bolígrafos de lectura",
        "复读机": "Repetidor",
        "翻译机": "Traductor",
        "早教益智": "Educación temprana y estimulación cognitiva",


	"运动户外":"Deportes al aire libre",


	"骑行运动":"Deporte de ciclismo",
 	"穿戴装备": "Equipamiento",
        "山地车": "Bicicleta de montaña",
        "城市自行车": "Bicicletas urbanas",
        "折叠车": "Bicicletas plegables",

	"户外装备":"Equipamiento de exterior",
        "旅游用品": "Productos turísticos",
        "户外照明": "Iluminación exterior",
        "便携桌椅床": "Mesas, sillas y camas portátiles",
        "帐篷/垫子": "Carpa / colchoneta",
        "冲浪潜水": "Surf y buceo",
        "野餐/烧烤": "Picnic / barbacoa",
        "望远镜": "Telescopio",
        "户外工具": "Herramientas de exterior",
        "军迷用品": "Suministros para aficionados militares",
        "登山攀岩": "Montañismo y escalada",
        "救援装备": "Equipo de rescate",
        "睡袋/吊床": "Saco de dormir / hamaca",
        "户外仪表": "Instrumentos al aire libre",
        "极限户外": "Deporte extremo",
        "滑雪装备": "Equipo de esquí",

	"垂钓用具":"Equipo de pesca",
        "鱼饵": "Cebo",
        "钓鱼配件": "Accesorios de pesca",
        "浮漂": "Flotador",
        "鱼线": "Línea de pesca",
        "鱼线轮": "Carrete de pesca",
        "钓箱钓椅": "Cajas y sillas de pesca",
        "渔具包": "Bolsa de aparejo de pesca",
        "钓鱼灯": "Lámpara de pesca",
        "辅助装备": "Equipos auxiliares",
        "钓竿": "Caña de pescar",

	"体育用品":"Artículos deportivos",
        "羽毛球拍": "Raqueta de bádminton",
        "棋牌麻将": "Juego de mesa",
        "篮球": "Baloncesto",
        "乒乓球拍": "Raqueta de tenis de Mesa",
        "轮滑滑板": "Patinaje y Monopatín",
        "足球": "Fútbol",
        "网球拍": "Raqueta de tenis",
        "田径鞋": "Zapatos de atletismo",
        "排球": "Voleibol",
        "台球桌": "Mesa de billar",
        "高尔夫球杆套杆": "Conjunto de palos de golf",
        "棒球": "Béisbol",
        "哑铃": "Mancuernas",

	"健身训练":"Entrenamiento de fitness",
        "瑜伽用品": "Artículos de yoga",
        "其他器械": "Otros equipos",
        "武术搏击": "Artes marciales",
        "跑步机": "Cintas de correr",
        "动感单车": "Bicicletas de spinnings",
        "甩脂机": "Máquina de lanzar grasa",
        "综合训练器": "Entrenador multifuncional",
        "椭圆机": "Máquina elíptica",
        "仰卧板/收腹机": "Placa supina / máquina abdominal",
        "踏步机": "Escaladora",


	"箱包":"Equipaje",


	"功能箱包":"Bolsa funcional",
 	"旅行配件": "Accesorios de viaje",
        "护脊书包": "Mochila protectora para la espalda",
        "行李箱": "Maleta",
        "儿童行李箱": "Maletas para niños",
        "儿童书包": "Mochilas escolares para niños",
        "旅行包": "Bolsa de viaje",

	"潮流女包":"Bolso de mujer de moda",
        "单肩包": "Bolso para un solo hombro",
        "迷你包": "Monedero",
        "手提包": "Cartera",
        "女士皮带": "Canguro de mujeres",
        "帆布包": "Bolsa de telas",
        "斜挎包": "Bolsa de hombro",

	"精品男包":"Bolso para hombres",
        "男士手机包": "Bolsa de teléfono móvil para hombres",
        "男士皮带": "Canguro para hombres",
        "单肩斜挎包": "Bolso para un solo hombro",
        "男士双肩包": "Mochila para hombres",
        "男士胸包": "Canguro de pecho para hombres",
        "商务公文包": "Maletín de Negocios",


	"钟表珠宝":"Relojes y joyas",


	"腕表":"Reloj",
        "国表": "Reloj Chino",
        "钟表配件": "Accesorios para relojes",
        "挂钟": "Reloj de pared",
        "闹钟": "Reloj de despertador",
        "智能手表": "Reloj inteligente",
        "儿童手表": "Relojes infantiles",

	"饰品":"Accesorios",
        "手链/脚链": "Pulsera / tobilleras",
        "项链": "Collar",
        "耳饰": "Aretes",
        "饰品配件": "Joyas y accesorios",
        "时尚戒指": "Anillo de moda",
        "胸针": "Broche",
        "发饰/发卡": "Accesorios para el cabello / horquillas",
        "水晶玛瑙": "ágata de cristal",


	"奢侈品":"Modelo de lujo",


	"男包":"Bolso para hombres",

	"女包":"Bolso para mujeres",

	"饰品":"Accesorios",

	"配饰":"Accesorios",


	"机械设备":"Maquinaria",


	"运输工具":"Medios de transporte",
 	"三轮车": "Triciclo",
        "货车": "Camión",
        "拖拉机": "Tractor",
        "飞行器": "Drone",
       

	"全部":"Todos",
}