import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n); // 全局挂载

export const i18n = new VueI18n({
	locale: localStorage.getItem("currentLang") || "es",
	messages: {
		"en": require("./lang/en"),
		"es": require("./lang/es"),
		"zh-CN": require("./lang/zh-CN"),
		"zh-TW": require("./lang/zh-TW"),
	}
});

export default i18n;